import { CardGroup, LoaderBar } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../AppContextProvider/AppContext";
import EmptyState from "../../components/EmptyState";
import { SiteCard } from "../../components/SiteCard";
import SiteListHeader, {
  SiteListHeaderActions,
} from "../../components/SiteListHeader";
import SiteListItem, {
  SiteListItemActions,
} from "../../components/SiteListItem";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { Site, SiteFragment, SitesOrderBy, SiteType } from "../../types.g";
import { SiteListType } from "./list";
import { Styled } from "./styles";
import {
  hasSitePermissionByContext,
  PERMISSION_GROUPS,
  PERMISSION_KEYS,
} from "./helpers/permissions";
import { AppContextType } from "src/AppContextProvider/type";
import { SearchSiteApolloProps, withSearchData } from "./apollo";
import { UseSearchSite } from "./hooks/useSearchSites";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

interface Props {
  searchTerms: string;
  viewType: string;
  sortBy: SiteListHeaderActions;
  onUpdateSiteClick: (site: SiteFragment) => void;
  onCastSiteCallback: (site: SiteFragment) => void;
  onDeleteSiteCallback: (site: SiteFragment) => void;
  onShareSiteCallback: (site: SiteFragment) => void;
  onClickCallback: (site: SiteFragment) => void;
  onSaveSiteNameCallback: (site: SiteFragment, name: string) => void;
  isSharedByOtherTabActive: boolean;
  setTotalCountCallback: ({
    totalCount,
    totalSharedCount,
  }: {
    totalCount: number;
    totalSharedCount: number;
  }) => void;
}
interface SearchSiteState {
  selectedSite: string[];
  selectedSiteData: SiteFragment[];
  sortBy: SiteListHeaderActions;
  visible: boolean;
  isFetchMore: boolean;
}

class SearchSiteList extends React.Component<
  Props & SearchSiteApolloProps,
  SearchSiteState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: Props & SearchSiteApolloProps) {
    super(props);
    this.state = {
      selectedSite: [],
      selectedSiteData: [],
      visible: false,
      isFetchMore: false,
      sortBy: SiteListHeaderActions.SORT_BY_DATE,
    };
  }
  public componentDidMount() {
    this.props.setTotalCountCallback({
      totalCount: this.props.searchSite.totalCount,
      totalSharedCount: this.props.searchSharedSite.totalCount,
    });
  }
  public componentDidUpdate(
    prevProps: Readonly<Props & SearchSiteApolloProps>,
    _prevState: Readonly<SearchSiteState>
  ) {
    if (
      prevProps.searchSite.totalCount !== this.props.searchSite.totalCount ||
      prevProps.searchSharedSite.totalCount !==
        this.props.searchSharedSite.totalCount
    ) {
      this.props.setTotalCountCallback({
        totalCount: this.props.searchSite.totalCount,
        totalSharedCount: this.props.searchSharedSite.totalCount,
      });
    }
  }

  public handleCallback = (
    site: Site,
    action: SiteListItemActions,
    value?: string
  ) => {
    switch (action) {
      case SiteListItemActions.DELETE:
        this.props.onDeleteSiteCallback(site);
        break;
      case SiteListItemActions.FAVORITE:
        // Add in favouriting functionality
        break;
      case SiteListItemActions.VIEW:
        this.props.onClickCallback(site);
        break;
      case SiteListItemActions.CAST:
        this.props.onCastSiteCallback(site);
        break;
      case SiteListItemActions.SHARE:
        this.props.onShareSiteCallback(site);
        break;
    }
  };

  public renderSiteList = (
    sites: SiteFragment[],
    itemType: string,
    showSecureSiteColumns: boolean,
    isShared?: boolean
  ): React.ReactNode[] => {
    const nodes: React.ReactNode[] = [];
    sites.map((site) => {
      if (itemType === "grid") {
        nodes.push(
          <SiteCard
            id={site.id}
            name={site.name || ""}
            siteType={site.type ? site.type : SiteType.Basic}
            site={site}
            canShare={
              hasSitePermissionByContext(
                this.context,
                PERMISSION_GROUPS.Site,
                PERMISSION_KEYS.Share
              ) && !isShared
            }
            canCast={hasSitePermissionByContext(
              this.context,
              PERMISSION_GROUPS.Screen,
              PERMISSION_KEYS.Cast
            )}
            canDelete={
              hasSitePermissionByContext(
                this.context,
                PERMISSION_GROUPS.Site,
                PERMISSION_KEYS.Delete
              ) && !isShared
            }
            canUpdate={hasSitePermissionByContext(
              this.context,
              PERMISSION_GROUPS.Site,
              PERMISSION_KEYS.Update
            )}
            thumbnail={
              site.fileByThumbnailId
                ? generateImgixThumbnail(
                    site.fileByThumbnailId,
                    this.context.secureMediaPolicy
                  )
                : ""
            }
            castSiteCallback={() => this.props.onCastSiteCallback(site)}
            deleteSiteCallback={() => this.props.onDeleteSiteCallback(site)}
            shareSiteCallback={() => this.props.onShareSiteCallback(site)}
            onClickCallback={() => this.props.onClickCallback(site)}
            onSaveSiteNameCallback={(name: string) =>
              this.props.onSaveSiteNameCallback(site, name)
            }
            onUpdateSiteClick={() => this.props.onUpdateSiteClick(site)}
          />
        );
      } else {
        nodes.push(
          <SiteListItem
            siteType={site.type}
            callBack={this.handleCallback}
            site={site}
            tags={site.tags as string[]}
            previewOpen={false}
            shareSiteCallback={() => this.props.onShareSiteCallback(site)}
            showSecureSiteColumns={showSecureSiteColumns}
            onSaveSiteNameCallback={(name: string) =>
              this.props.onSaveSiteNameCallback(site, name)
            }
            canUpdate={hasSitePermissionByContext(
              this.context,
              PERMISSION_GROUPS.Site,
              PERMISSION_KEYS.Update
            )}
          />
        );
      }
    });
    return nodes;
  };

  public handleHeaderChange = (action: SiteListHeaderActions) => {
    this.setState({
      sortBy: action,
    });
    const orderBy =
      action === SiteListHeaderActions.SORT_BY_NAME
        ? [SitesOrderBy.NameAsc, SitesOrderBy.CreatedAtDesc]
        : [SitesOrderBy.CreatedAtDesc, SitesOrderBy.NameAsc];
    const spaceId = this.context.user.settings.spaceId;
    if (this.props.isSharedByOtherTabActive) {
      this.props.searchSharedSite.refetch({
        orderBy,
        spaceId,
        filter: { spaceId: { notEqualTo: spaceId } },
        first: FIRST_FETCH_ITEMS,
      });
    } else {
      this.props.searchSite.refetch({
        orderBy,
        spaceId,
        filter: { spaceId: { equalTo: spaceId } },
        first: FIRST_FETCH_ITEMS,
      });
    }
  };

  public getSitesHeader(
    showSortButton: boolean,
    viewType: string,
    listType: SiteListType,
    showSecureSiteColumns: boolean
  ) {
    return (
      <SiteListHeader
        viewType={viewType}
        listType={listType}
        callback={(action) => this.handleHeaderChange(action)}
        sortBy={this.props.sortBy}
        showSortButton={showSortButton}
        showSecureSiteColumns={showSecureSiteColumns}
      />
    );
  }

  public getSitesList(
    searchSites: UseSearchSite,
    viewType: string,
    isShared?: boolean
  ) {
    const { sites, renderFetchMoreButton } = searchSites;
    const hasSecureSite = sites.some((site) => site.type === SiteType.Secure);

    const spaceSitesList = this.renderSiteList(
      sites,
      viewType,
      hasSecureSite,
      isShared
    );

    return (
      <>
        {sites.length > 0 && (
          <div>
            {viewType === "list" &&
              this.getSitesHeader(
                sites.length > 0,
                viewType,
                SiteListType.SPACE,
                hasSecureSite
              )}
            {viewType === "grid" ? (
              <div className="sub-wrapper">
                <CardGroup>
                  {spaceSitesList}
                  {renderFetchMoreButton}
                </CardGroup>
              </div>
            ) : (
              <>
                {spaceSitesList}
                {renderFetchMoreButton}
              </>
            )}
          </div>
        )}
      </>
    );
  }

  public render() {
    const isLoading =
      this.props.searchSite.isLoading || this.props.searchSharedSite.isLoading;

    if (this.props.searchSite || this.props.searchSharedSite) {
      const sites = this.getSitesList(
        this.props.searchSite,
        this.props.viewType
      );
      const sharedSites = this.getSitesList(
        this.props.searchSharedSite,
        this.props.viewType,
        true
      );

      let shouldShowEmptyState = false;
      if (this.props.isSharedByOtherTabActive) {
        shouldShowEmptyState = this.props.searchSharedSite.totalCount === 0;
      } else {
        shouldShowEmptyState = this.props.searchSite.totalCount === 0;
      }
      return (
        <Styled>
          {isLoading && <LoaderBar />}
          <div className="site-search-results">
            {shouldShowEmptyState ? (
              <EmptyState section="search-links">
                <h3>
                  <FormattedMessage
                    id="common.search.no_results"
                    defaultMessage="We couldn’t find any matches"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="common.search.no_results_description"
                    defaultMessage="Try adjusting or using different search terms."
                  />
                </p>
              </EmptyState>
            ) : this.props.isSharedByOtherTabActive ? (
              sharedSites
            ) : (
              sites
            )}
          </div>
        </Styled>
      );
    } else {
      return null;
    }
  }
}

export default withSearchData(SearchSiteList) as React.ComponentType<Props>;
