import {
  AccountNavActions,
  Button,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  LoaderBar,
  Logo,
  ModalSize,
  Notification,
} from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import classNames from "clsx";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../utils/compose";

import { EntityType } from "@screencloud/signage-firestore-client";
import { AppContext } from "../../AppContextProvider/AppContext";
import CastingList from "../../components/Casting";
import OrgSwitcher from "../../components/OrgSwitcher";
import Sidebar from "../../components/Sidebar";
import {
  CANVAS_ROUTE,
  Locale,
  SUBSCRIPTION_STATUS,
  UUID,
} from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { ssm } from "../../state/session/ssm";

import { ApolloQueryResult } from "@apollo/client";
import { hubspotFormSubmit } from "../../helpers/hubspotHelper";
import {
  WhiteLabelStyles,
  getWhiteLabelGlyph,
  getWhiteLabelOrganizationName,
  getWhiteLabelStyles,
} from "../../helpers/whiteLabel";
import { subscribeToListUpdates } from "../../state/liveUpdateManager";
import {
  CastedScreensCountQuery,
  useCastedScreensCountQuery,
} from "../../types.g";
import AccountNav from "../AccountNav";
import MultiSpaceSearch from "../MultiSpaceSearch/index";
import { FeedbackData, FeedbackModal } from "../FeedbackModal";
import { LogoBanner } from "../Logo/LogoBanner/LogoBanner";
import {
  MainNavButton,
  StyledMainNav,
  StyledMobileHeader,
  SupportNavDropdown,
  SupportNavReseller,
} from "./styles";
import { AppContextType } from "src/AppContextProvider/type";
import { useCurrentUserInterfaceVisibilities } from "src/userInterfaceVisibility/useUserInterfaceVisibilities";
import { getSection } from "src/domains/route/location";
import { getCastedScreenCount } from "src/domains/screens/castedScreenCount";
import {
  UseUpdateCurrentUserSetting,
  useUpdateCurrentUserSetting,
} from "src/hooks/applications/currentUserSetting/useUpdateCurrentSpace";
import { ScreenCloudContactUrl } from "../../constants/url";
import {
  getResellerSupportLink,
  isResellerShowSupport,
} from "src/domains/reseller/reseller";
import { getPrefillDemoRequestUrl } from "src/pages/Sites/LandingPage/utils";
import { openScosStore } from "../ScosStore";
import { GetStartedKCBot } from "../GetStartedKCBot";
import { shouldHideGetStartedKCBot } from "./utils";
import ChangeLogs from "../ChangeLogs";
import GoyucuIcon from "./img/goyucu-icon.svg";
import Forbidden from "src/pages/Forbidden";
import { APP_STORE_DISCOVER_PATH_NANE } from "src/pages/Apps/AppStore/constants";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";

export type WithMainMenu = UseUpdateCurrentUserSetting & {
  section: string;
  refetch: (
    variables?: Partial<{ spaceId: UUID }>
  ) => Promise<ApolloQueryResult<CastedScreensCountQuery>>;
  isShowChannel: boolean;
  isShowPlaylist: boolean;
  isShowApp: boolean;
  isShowDashboard: boolean;
  isShowLink: boolean;
  isShowScreen: boolean;
  isShowMedia: boolean;
  isShowQrCodeMetrics: boolean;
  isShowSupport: boolean;
  currentCastingScreens: number;
  isMobileView: boolean;
};

export type MainMenuProps = {
  onLanguageChange: (locale: Locale) => void;
};

const withMainMenu = compose((Component) => (props: WithMainMenu) => {
  const { data: castedScreensCountQuery, refetch } = useCastedScreensCountQuery(
    {
      fetchPolicy: "cache-and-network",
      variables: { spaceId: ssm.current.settings.spaceId },
    }
  );
  const currentCastingScreens = getCastedScreenCount(castedScreensCountQuery);
  const {
    mainMenu: {
      isShowApp,
      isShowScreen,
      isShowLink,
      isShowDashboard,
      isShowChannel,
      isShowPlaylist,
      isShowMedia,
      isShowQrCodeMetrics,
      isShowSupport,
    },
  } = useCurrentUserInterfaceVisibilities();
  const location = useLocation();
  const section = getSection(location);
  const { isMobileView } = useGetScreenSize();

  const mainMenuProps: WithMainMenu = {
    refetch,
    currentCastingScreens,
    section,
    isShowDashboard,
    isShowLink,
    isShowScreen,
    isShowApp,
    isShowChannel,
    isShowPlaylist,
    isShowMedia,
    isShowQrCodeMetrics,
    isShowSupport,
    isMobileView,
    ...useUpdateCurrentUserSetting(),
  };

  return (
    <Component {...props} {...mainMenuProps} isMobileView={isMobileView} />
  );
});

export class MainMenu extends Component<MainMenuProps & WithMainMenu> {
  public static contextType = AppContext;
  public context: AppContextType;
  public readonly state = { open: false, isSubscribed: false };
  private unsubscribeLiveUpdateFn?: () => void;

  public componentDidUpdate() {
    const { currentUser } = this.context;

    if (!this.state.isSubscribed && currentUser) {
      this.subscribeToLiveUpdate();
    }
  }

  public componentWillUnmount() {
    this.unsubscribeFromLiveUpdate();
  }

  public onClickMenu = async (): Promise<void> => {
    await this.handleMobileNavToggle();
  };
  public onLanguageChange = (locale: string) => {
    this.props.onLanguageChange(locale as Locale);
  };
  public handleMobileNavToggle = () => {
    this.setState({ open: !this.state.open });
  };
  public handleAccountNavClick = async (action) => {
    const { updateCurrentSpace } = this.props;
    switch (action) {
      case AccountNavActions.Logout:
        this.context.logout();
        break;
      case AccountNavActions.Settings:
        window.location.href = "/account";
        break;
      case "switchOrg":
        this.context.modal.openModal(
          <OrgSwitcher />,
          <FormattedMessage
            id="ui_component.account_nav.switch_org"
            defaultMessage="Switch Organization"
          />,
          {
            opts: {
              disableTitle: false,
              overflow: false,
              size: ModalSize.SMALL,
              className: "fix-height",
            },
          }
        );
        break;
      case "screensManager":
        this.context.history.push("/screens-manager");
        break;
      default:
        // TODO: Temap hack, update this when I fix the AccountNav component
        if (action.type === "space") {
          const space = { id: action.id, name: action.name };
          updateCurrentSpace(space);
        }
        break;
    }
  };

  public showCasting = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.context.modal.openNavigationControlModal(
      <CastingList refetchParent={() => this.context.refetchCurrentUser()} />,
      this.context.intl.formatMessage({
        defaultMessage: "Casts",
        id: "screens.button.casts",
      }),
      { opts: { size: ModalSize.LARGE } }
    );
  };

  public subscribeToLiveUpdate = () => {
    const { currentUser } = this.context;
    this.setState({ isSubscribed: true });
    if (currentUser) {
      if (this.unsubscribeLiveUpdateFn) {
        this.unsubscribeLiveUpdateFn();
        this.unsubscribeLiveUpdateFn = undefined;
      }
      this.unsubscribeLiveUpdateFn = subscribeToListUpdates(
        currentUser.orgId || "",
        EntityType.SCREEN,
        () => {
          this.props.refetch();
        }
      );
    }
  };

  public unsubscribeFromLiveUpdate = () => {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  };

  public onClickOpenNewTab = (url) => {
    window.open(url, "_blank");
  };

  public renderLogo = () => {
    const { currentUser } = this.context;
    const { isShowScreen } = this.props;

    if (!currentUser) {
      // TODO: Check we need loader here... might clash with child components
      return <LoaderBar />;
    }

    if (this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.WHITE_LABEL)) {
      return (
        <LogoBanner
          className="logo-banner-mainmenu-wrapper"
          url={isShowScreen ? "/" : undefined}
          organizationName={getWhiteLabelOrganizationName(this.context)}
          glyph={getWhiteLabelGlyph(this.context)}
          height={32}
          glyphBorderRadius={8}
        />
      );
    }

    const logo: JSX.Element = (
      <Logo url={isShowScreen ? "/" : undefined} className="logo" dark />
    );
    return logo;
  };

  public renderMainNavigation = () => {
    const {
      isShowDashboard,
      isShowLink,
      isShowScreen,
      isShowApp,
      isShowChannel,
      isShowPlaylist,
      isShowMedia,
      section,
      currentCastingScreens,
      isShowQrCodeMetrics,
    } = this.props;
    const whiteLabelStyles = getWhiteLabelStyles(this.context);
    const mainNavigation: JSX.Element = (
      <>
        <MultiSpaceSearch
          {...whiteLabelStyles}
          onItemClick={this.handleAccountNavClick}
        />
        <StyledMainNav {...whiteLabelStyles} className="sidebar-top">
          <div
            className={
              currentCastingScreens > 0 ? "wrapper casting" : "wrapper"
            }
            data-testid="main-nav"
          >
            {isShowScreen && (
              <MainNavButton
                {...whiteLabelStyles}
                id="screens-button"
                onClick={this.handleMobileNavToggle}
                as={Link}
                to={`/screens`}
                className={section === "screens" ? "button active" : "button"}
                title="Screens"
              >
                <Icon name="screen" />
                <FormattedMessage
                  id="ui_component.main_navigation.screens"
                  defaultMessage="Screens"
                />
                {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
                  currentCastingScreens > 0 && (
                    <Button
                      title={this.context.intl.formatMessage(
                        {
                          defaultMessage:
                            "Casting on {num, number} {num, plural, one {screen} other {screens}}",
                          id: "casting_on_screens",
                        },
                        {
                          num: currentCastingScreens,
                        }
                      )}
                      mini
                      className="casting"
                      borderless
                      inverted
                      onClick={this.showCasting}
                    >
                      <Icon name="casting" />
                      <span>{currentCastingScreens}</span>
                    </Button>
                  )}
              </MainNavButton>
            )}
            <div id="organize-group">
              {isShowChannel && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="channels-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={`/channels`}
                  className={
                    section === "channels" ? "button active" : "button"
                  }
                  title="Channels"
                >
                  <Icon name="channel" />
                  <FormattedMessage
                    id="ui_component.main_navigation.channels"
                    defaultMessage="Channels"
                  />
                </MainNavButton>
              )}

              {isShowPlaylist && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="playlists-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={`/playlists`}
                  className={
                    section === "playlists" ? "button active" : "button"
                  }
                  title="Playlists"
                >
                  <Icon name="playlist" />
                  <FormattedMessage
                    id="ui_component.main_navigation.playlists"
                    defaultMessage="Playlists"
                  />
                </MainNavButton>
              )}
            </div>

            <div id="media-group">
              {isShowMedia && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="media-button"
                  onClick={this.onClickMenu}
                  as={Link}
                  to={`/media`}
                  className={section === "media" ? "button active" : "button"}
                  title="Media"
                >
                  <Icon name="folder" />
                  <FormattedMessage
                    id="ui_component.main_navigation.media"
                    defaultMessage="Media"
                  />
                </MainNavButton>
              )}

              {isShowLink && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="links-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={`/links`}
                  className={section === "links" ? "button active" : "button"}
                  title="Links"
                >
                  <Icon name="link" />
                  <FormattedMessage
                    id="ui_component.main_navigation.links"
                    defaultMessage="Links"
                  />
                </MainNavButton>
              )}
              {isShowDashboard && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="dashboards-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={`/dashboards`}
                  className={
                    section === "dashboards" ? "button active" : "button"
                  }
                  title="Dashboards"
                >
                  <Icon name="dashboard" />
                  <FormattedMessage
                    id="ui_component.main_navigation.dashboards"
                    defaultMessage="Dashboards"
                  />
                </MainNavButton>
              )}

              {isShowApp && (
                <MainNavButton
                  {...whiteLabelStyles}
                  id="canvas-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={CANVAS_ROUTE.CANVAS_TEMPLATE_GALLERY}
                  className={
                    section === "canvas" ||
                    section === "canvas-templates" ||
                    section === "canvas-template-gallery"
                      ? "button active"
                      : "button"
                  }
                  title="Canvas"
                >
                  <Icon name="canvas-brush" />
                  <FormattedMessage
                    id="ui_component.main_navigation.canvas"
                    defaultMessage="Canvas"
                  />
                </MainNavButton>
              )}
            </div>

            {isShowApp && (
              <MainNavButton
                {...whiteLabelStyles}
                id="apps-button"
                onClick={this.handleMobileNavToggle}
                as={Link}
                to={APP_STORE_DISCOVER_PATH_NANE}
                className={
                  section === "apps" ||
                  section === "apps-store" ||
                  section === "apps-shared"
                    ? "button active"
                    : "button"
                }
                title="Apps"
              >
                <Icon name="app" />
                <FormattedMessage
                  id="ui_component.main_navigation.apps"
                  defaultMessage="Apps"
                />
              </MainNavButton>
            )}
            {isShowQrCodeMetrics && (
              <>
                <div className="divider" />
                <MainNavButton
                  {...whiteLabelStyles}
                  id="metrics-button"
                  onClick={this.handleMobileNavToggle}
                  as={Link}
                  to={`/metrics`}
                  className={section === "metrics" ? "button active" : "button"}
                  title="Metrics"
                >
                  <Icon name="chart" />
                  <FormattedMessage
                    id="ui_component.main_navigation.metrics"
                    defaultMessage="Metrics"
                  />
                </MainNavButton>
              </>
            )}

            {isResellerShowSupport(this.context.currentOrg?.id) && (
              <MainNavButton
                {...whiteLabelStyles}
                id="goyucu-button"
                as={"a"}
                target="_blank"
                href="https://goyucu.ridgelogic.com/login/"
                onClick={this.handleMobileNavToggle}
                className="button goyucu"
                title="Login"
              >
                <img height={50} src={GoyucuIcon} />
                <FormattedMessage
                  id="ui_component.main_navigation.goyucu_login"
                  defaultMessage="Login"
                />
              </MainNavButton>
            )}
          </div>
        </StyledMainNav>
      </>
    );

    return mainNavigation;
  };

  public onFeedbackSubmit = async (data: FeedbackData) => {
    const { firstName, lastName, email, feedback } = data;
    const formData = {
      submittedAt: new Date(),
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "feedback",
          value: feedback,
        },
        {
          name: "orgid",
          value: this.context.currentOrg?.id,
        },
        {
          name: "orgname",
          value: this.context.currentOrg?.name,
        },
        {
          name: "url",
          value: window.location.origin,
        },
      ],
    };

    const status = await hubspotFormSubmit(formData);
    if (status !== 200) {
      setTimeout(() => {
        Notification.error({
          message: (
            <FormattedMessage
              id="feedback.error_message"
              defaultMessage="Your feedback submission was unsuccessful. Please try again"
            />
          ),
        });
      }, 50);
    } else {
      setTimeout(() => {
        Notification.success({
          message: (
            <FormattedMessage
              id="feedback.success_message"
              defaultMessage="Thanks you for your feedback! A member of our Product Team will review your feedback soon and may contact you"
            />
          ),
        });
      }, 50);
    }

    this.context.modal.closeModals();
  };

  public renderSupportNav = (
    whiteLabelStyles: false | WhiteLabelStyles | null
  ) => {
    const orgId = this.context.currentOrg?.id;
    if (isResellerShowSupport(orgId)) {
      return (
        <SupportNavReseller
          onClick={() => this.onClickOpenNewTab(getResellerSupportLink(orgId))}
          className="support-nav-reseller"
          data-testid="support-nav-reseller"
        >
          <Icon name="help" />
          <FormattedMessage
            id="ui_component.main_navigation.support"
            defaultMessage="Support"
          />
        </SupportNavReseller>
      );
    } else {
      return (
        <SupportNavDropdown
          {...whiteLabelStyles}
          className="support"
          data-testid="support-nav"
          trigger={
            <div className="support-nav">
              <Icon name="support" />
              <FormattedMessage
                id="ui_component.main_navigation.support"
                defaultMessage="Support"
              />
              <Icon name="arrow-right" />
            </div>
          }
          pointing="left"
          title="Support"
        >
          <DropdownMenu id="support-nav-menu" className="support-nav-menu">
            {!shouldHideGetStartedKCBot(this.context) && (
              <DropdownItem
                data-testid="get-started-kc-bot"
                id="kcb-widget"
                title="Get Started"
              >
                <Icon name="rocket" />
                <FormattedMessage
                  id="ui_component.main_navigation.get_started_kc_bot"
                  defaultMessage="Get Started"
                />
              </DropdownItem>
            )}
            <DropdownItem
              data-testid="screen-setup-video"
              title="Screen Setup Video"
              className="wistia_embed wistia_async_p40hmtg6wx popover=true popoverContent=link"
            >
              <Icon name="play-circle" />
              <FormattedMessage
                id="ui_component.main_navigation.screen_setup_video"
                defaultMessage="Screen Setup Video"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="training-hub-link"
              onClick={() =>
                this.onClickOpenNewTab("https://university.screencloud.com")
              }
              title="ScreenCloud University"
            >
              <Icon name="education" />
              <FormattedMessage
                id="ui_component.main_navigation.screencloud_university"
                defaultMessage="ScreenCloud University"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="help-center-link"
              onClick={() =>
                this.onClickOpenNewTab("https://help.screencloud.com/")
              }
              title="Help Center"
            >
              <Icon name="help" />
              <FormattedMessage
                id="ui_component.main_navigation.help.center"
                defaultMessage="Help Center"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="screencloud-stories"
              onClick={() =>
                this.onClickOpenNewTab(
                  "https://screencloud.com/digital-transformation/screencloud-stories"
                )
              }
              title="ScreenCloud Stories"
            >
              <Icon name="guide" />
              <FormattedMessage
                id="ui_component.main_navigation.screencloud_stories"
                defaultMessage="ScreenCloud Stories"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="sc-website-link"
              onClick={() => this.onClickOpenNewTab("https://screencloud.com")}
              title="Go to ScreenCloud.com"
            >
              <Icon name="basic-site" />
              <FormattedMessage
                id="ui_component.main_navigation.screencloudcom"
                defaultMessage="Go to ScreenCloud.com"
              />
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem
              data-testid="product-feedback-link"
              onClick={() =>
                this.context.modal.openModal(
                  <FeedbackModal onSubmit={this.onFeedbackSubmit} />,
                  null,
                  {
                    opts: {
                      closeOnDimmerClick: true,
                      overflow: false,
                      size: ModalSize.SMALL,
                      disableTitle: true,
                    },
                  }
                )
              }
              title="Give Feedback"
            >
              <Icon name="feedback" />
              <FormattedMessage
                id="common.give_feedback"
                defaultMessage="Give Feedback"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="contact-us-link"
              onClick={() => this.onClickOpenNewTab(ScreenCloudContactUrl)}
              title="Contact Us"
            >
              <Icon name="mail" />
              <FormattedMessage
                id="ui_component.main_navigation.contact.us"
                defaultMessage="Contact Us"
              />
            </DropdownItem>
            {this.context.subscription?.status ===
              SUBSCRIPTION_STATUS.IN_TRIAL && (
              <DropdownItem
                data-testid="book-demo"
                onClick={() =>
                  this.onClickOpenNewTab(
                    getPrefillDemoRequestUrl(this.context.currentUser)
                  )
                }
                title="Book a Demo"
              >
                <Icon name="screen-guide" />
                <FormattedMessage
                  id="ui_component.main_navigation.book_demo"
                  defaultMessage="Book a Demo"
                />
              </DropdownItem>
            )}
            <DropdownDivider />
            <DropdownItem disabled className="subheader">
              <span>ScreenCloud OS</span>
            </DropdownItem>
            <DropdownItem
              data-testid="sc-os-link"
              onClick={() =>
                this.onClickOpenNewTab("https://screencloud.com/os")
              }
              title="About ScreenCloud OS"
            >
              <Icon name="os" />
              <FormattedMessage
                id="ui_component.main_navigation.about_screencloud_os"
                defaultMessage="About ScreenCloud OS"
              />
            </DropdownItem>
            <DropdownItem
              data-testid="store-sc-link"
              onClick={() =>
                openScosStore({
                  context: this.context,
                  isFromDeviceInformationModal: false,
                })
              }
              title="Go to Store"
            >
              <Icon name="store" />
              <FormattedMessage
                id="ui_component.main_navigation.go_to_store"
                defaultMessage="Go to Store"
              />
            </DropdownItem>
          </DropdownMenu>
        </SupportNavDropdown>
      );
    }
  };

  public render(): JSX.Element {
    const {
      currentUser,
      currentPermissions,
      shouldShowFeature,
      redirectToDefaultPath,
    } = this.context;
    const { isShowSupport } = this.props;

    if (!currentPermissions.validateCurrentSpace("space", "read")) {
      redirectToDefaultPath();
      return <Forbidden />;
    }
    if (!currentUser) {
      // TODO: Check we need loader here... might clash with child components
      return <LoaderBar />;
    }

    const whiteLabelStyles = getWhiteLabelStyles(this.context);

    const accountNav: JSX.Element = (
      <AccountNav
        {...whiteLabelStyles}
        currentSpace={this.context.user.settings.spaceId}
        onItemClick={this.handleAccountNavClick}
        name={
          this.context.user.claims.givenName! || this.context.user.claims.email!
        }
        space={this.context.currentSpace?.name ?? ""}
        spaces={this.context.allSpaces}
      />
    );

    return (
      <>
        <StyledMobileHeader
          {...whiteLabelStyles}
          open={this.state.open}
          className={classNames("mobile-header", {
            isOpen: this.props.isMobileView && this.state.open,
          })}
          data-qa="mobile-header"
        >
          <Button
            transparent
            className="menu-toggle"
            toggle
            active={this.state.open}
            onMouseDown={this.handleMobileNavToggle}
          >
            <div className="one" />
            <div className="two" />
            <div className="three" />
          </Button>
          {this.renderLogo()}
          {accountNav}
          <div className="overlay">{this.renderMainNavigation()}</div>
        </StyledMobileHeader>

        <Sidebar {...whiteLabelStyles}>
          {this.renderLogo()}
          <div className="menu" data-testid="desktop-menu">
            {this.renderMainNavigation()}
            <GetStartedKCBot />
            {!shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER) && <ChangeLogs />}
            <div className="sidebar-bottom" data-testid="sidebar-bottom">
              {isShowSupport && this.renderSupportNav(whiteLabelStyles)}
            </div>
          </div>
          {accountNav}
        </Sidebar>
      </>
    );
  }
}

export default withMainMenu(MainMenu) as React.ComponentType<MainMenuProps>;
