import {
  Button,
  CloseButtonPosition,
  Icon,
  LoaderBar,
  ModalSize,
  Tab,
} from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from "react-beautiful-dnd";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import {
  AppTabPaneItemActionEnum,
  AppTabPaneItemActions,
} from "../../../components/AppTabPane/appItem";
import Container from "../../../components/Cont";
import { LinkListItemActions } from "../../../components/LinkListItem";
import Main from "../../../components/Main";
import { MediaFileActions } from "../../../components/Media/mediaFile";
import { MediaPickerActionMode } from "../../../components/MediaPicker/media";
import MediaSidebar from "../../../components/MediaSidebar";
import PlayerPreview, { PreviewType } from "../../../components/PlayerPreview";
import { PlaylistContentItemActions } from "../../../components/Playlist/PlaylistContentItem";
import SidebarRight from "../../../components/SidebarRight";
import { SiteListItemActions } from "../../../components/SiteListItem";
import { v4 as uuidv4 } from "uuid";

import {
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_GLOBAL_DURATION,
  ListContentItem,
  RefType,
  TitlePageSufix,
  UUID,
} from "../../../constants/constants";
import { getTotalTimeDuration, removeEmptyFromArray } from "../../../utils";
import Forbidden from "../../Forbidden";
import { ApolloProps, withData } from "./apollo";
import {
  fileToPlaylistContentMapping,
  linkToPlaylistContentMapping,
  siteToPlaylistContentMapping,
  mappingActivePlaylistItems,
  getValidPlaylistItems,
  getPlaylistItemsDuration,
  doesContainExpiredPlaylistItems,
  getFormattedPlaylistDataForCacheListing,
  getInitialDurations,
} from "./helper";

import { ApolloQueryResult } from "@apollo/client";
import { MediaFolderActions } from "../../../components/Media/mediaFolder";
import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import { appendNewTags } from "../../../helpers/tagHelper";
import { subscribeToDocumentUpdates } from "../../../state/liveUpdateManager";

import { isDocument } from "../../../helpers/mediaHelper";
import { canBeUpdated, isOwner } from "../../../helpers/shareableHelper";

import { sortedByName } from "../../../helpers/sortingHelper";
import {
  AllTagsDocument,
  AllTagsQuery,
  AppInstance,
  DuplicatePlaylistMutationVariables,
  File,
  FileOutput,
  FileOutputFragment,
  Folder,
  JobStatus,
  Link,
  NestedFilesByFolderIdDocument,
  NestedFilesByFolderIdQuery,
  Playlist,
  PlaylistByIdDataFragment,
  PlaylistByIdDocument,
  PlaylistByIdQuery,
  PlaylistListItemFragmentDoc,
  PublishDraftPlaylistMutationVariables,
  RevertDraftPlaylistMutationVariables,
  Scalars,
  Site,
  UpdatePlaylistColorMutationVariables,
  UpdatePlaylistMutationVariables,
  UpdatePlaylistTagsMutation,
  UpdatePlaylistTagsMutationVariables,
} from "../../../types.g";
import PlaylistOptions, {
  PlaylistOptionAction,
  PlaylistOptionActionEnum,
  PlaylistOptionDataType,
} from "./options";
import PlaylistContentList from "./playlistContentList";
import PlaylistDetailHeader from "./PlaylistDetailHeader";
import { Styled } from "./styles";
import { useAppContext } from "../../../hooks/useAppContext";
import {
  canBePublished,
  updateNewPlaylistToList,
} from "../../../helpers/playlistHelper";
import { ScreenPickerActions } from "../../../../src/components/ScreenPicker";
import PageContainer from "src/components/PageContainer";
import { getCurrentUserInterfaceVisibilitiesByContext } from "src/userInterfaceVisibility/useUserInterfaceVisibilities";
import { getAppInstanceDuration } from "src/helpers/appHelper";
import { getDuplicateInstanceName } from "src/utils/duplication";

const md5 = require("md5");

export enum PlaylistDetailMode {
  Preview,
  Edit,
}

interface Props extends ApolloProps {
  mode: PlaylistDetailMode;
  playlistId: UUID;
  playlistById: PlaylistByIdQuery["playlistById"];
  isFullScreen: boolean;
  onEditPlaylistClick: () => void;
  onPreviewPlaylistClick: () => void;
  onPreviewPlaylistFromDetailPageClick: () => void;
  onGoBackToPlaylistListPage: () => void;
  onPreviewFullscreen: () => void;
}
export interface PlaylistItem {
  id: string;
  list_id: string;
  contentId: string;
  mimeType: string | JSON | undefined;
  metadata: string | JSON;
  name: string;
  customDuration: number;
  duration: number;
  thumbnail: string;
  refType: RefType;
  isFixedDuration?: boolean;
  isExpired?: boolean;
  imageSet?: FileOutput;
}

export interface ActivePlaylistItem {
  id: string;
  list_id: string;
  dragableId: string;
  name: string | null | undefined;
  refType: RefType;
  customDuration: number;
  duration: number;
  mimeType: string | null | undefined;
  isFixedDuration?: boolean;
  imageSet?: FileOutputFragment;
  isExpired?: boolean;
}

const isDataLoaded = (props: Props) => {
  return Boolean(props.playlistById?.draft);
};

const onCreateContentRefObject = (
  playlistItems: ActivePlaylistItem[]
): ListContentItem[] => {
  playlistItems = removeEmptyFromArray(playlistItems); // hack, need to improve logic
  return playlistItems.reduce((previous: ListContentItem[], current) => {
    let duration = current.customDuration;
    if (current.mimeType === "AppInstance" && current.isFixedDuration) {
      duration = current.duration;
    }
    const newContent = {
      content: {
        _ref: {
          id: current.id,
          type: current.refType,
        },
        meta: {}, // It may not be empty if the file type is video
        props: {
          duration,
        },
      },
      list_id: current.list_id,
    } as ListContentItem;
    return [...previous, newContent];
  }, []);
};

const refetchQueries = (playlistId) => {
  const variable: { [key: string]: any } = {
    id: playlistId,
  };

  return {
    query: PlaylistByIdDocument,
    variables: variable,
  };
};

const isContentPropsUptodate = (props: Props, lastSaveAttempt: string) => {
  if (props?.playlistById?.draft) {
    const { draft } = props.playlistById;
    const isUpdatedContentMatch =
      lastSaveAttempt === "" ||
      lastSaveAttempt === md5(JSON.stringify(draft.content));
    const isContentGotFilesData =
      !draft.content ||
      !draft.content.nodes ||
      (draft.content.nodes &&
        draft.content.nodes.length > 0 &&
        draft.filesByPlaylistId.nodes.length > 0);
    return isUpdatedContentMatch && isContentGotFilesData;
  }
  return false;
};

const isPreviewMode = (context, playlist) => {
  return !(
    canBeUpdated({ context, shareable: playlist }) ||
    canBePublished({ context, playlist })
  );
};

export const renderPlayerPreview = (
  props: Props,
  context,
  playlist: PlaylistByIdDataFragment
) => {
  const isPlaylistOwner = context.user.settings.spaceId === playlist?.spaceId;
  const playlistDetail = isPlaylistOwner ? playlist?.draft : playlist;
  const { onPreviewFullscreen, onEditPlaylistClick } = props;
  if (playlistDetail) {
    if (!isPreviewMode(context, playlist) && !props.isFullScreen) {
      return (
        <PlayerPreview
          id={playlistDetail.id}
          onFullScreenClick={onPreviewFullscreen}
          showFullScreenButton
          previewType={PreviewType.PLAYLIST}
          onEditButonClick={onEditPlaylistClick}
          isOwner={isPlaylistOwner}
        />
      );
    } else {
      context.modal.openModal(
        <PlayerPreview
          id={playlistDetail?.id}
          previewType={PreviewType.PLAYLIST}
          onEditButonClick={() => context.modal.closeModals()}
          isOwner={isPlaylistOwner}
        />,
        null,
        {
          opts: {
            closeButtonPosition: CloseButtonPosition.LEFT,
            disableTitle: true,
            size: ModalSize.FULLSCREEN,
          },
          onClose: () => {
            const canUpdatePlaylist = context.currentPermissions.validateCurrentSpace(
              "playlist",
              "update"
            );
            if (!canUpdatePlaylist || !isPlaylistOwner) {
              context.history.push(`/playlists/`);
            }
          },
        }
      );
    }
  }
  return <div />;
};

let debounceUpdatePlaylistTimeout;
let debounceUpdateCustomDuration;

const cancelDebounceUpdateCustomDuration = () => {
  if (debounceUpdateCustomDuration) {
    clearTimeout(debounceUpdateCustomDuration);
    debounceUpdateCustomDuration = null;
  }
};
const cancelDebounceUpdate = () => {
  if (debounceUpdatePlaylistTimeout) {
    clearTimeout(debounceUpdatePlaylistTimeout);
    debounceUpdatePlaylistTimeout = null;
  }
};
const debounceUpdatePlaylist = (
  updatePlayList: (
    playlistInput: UpdatePlaylistMutationVariables
  ) => Promise<void>,
  playlistInput: UpdatePlaylistMutationVariables
) => {
  cancelDebounceUpdate();
  debounceUpdatePlaylistTimeout = setTimeout(() => {
    updatePlayList(playlistInput);
  }, DEBOUNCE_TIMEOUT_MS);
};

export const PlaylistDetail = (props: Props) => {
  const { playlistById } = props;
  let unsubscribeLiveUpdateFn;
  let unsubscribeLiveUpdateFnDraft;
  const context = useAppContext();
  const [playlist, setPlaylist] = useState(props?.playlistById);
  const [playlistItems, setPlaylistItems] = useState<ActivePlaylistItem[]>([]);
  const [defaultDurationData, setDefaultDurationData] = useState<
    Scalars["JSON"]
  >(playlistById?.draft?.content.props.default_durations);
  const [shouldScrollToItem, setShouldScrollToItem] = useState("");
  const [selectedMediaItems, setSelectedMediaItems] = useState<File[]>([]);
  const [selectedLinkItems, setSelectedLinkItems] = useState<Link[]>([]);
  const [selectedSiteItems, setSelectedSiteItems] = useState<Site[]>([]);
  const [selectedMediaItem, setSelectedMediaItem] = useState<
    File | Folder | null
  >(null);
  const [selectedLinkItem, setSelectedLinkItem] = useState<Link | null>(null);
  const [selectedSiteItem, setSelectedSiteItem] = useState<Site | null>(null);
  const [selectedAppItem, setSelectedAppItem] = useState<AppInstance | null>(
    null
  );
  const [shouldRefetchingSidebar, setShouldRefetchingSidebar] = useState(false);
  const [isHidePublishAnimation, setIsHidePublishAnimation] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [lastSaveAttempt, setLastSaveAttempt] = useState("");
  const [customDurationUpdates, setCustomDurationUpdates] = useState<
    Record<string, number>
  >({});
  const isInitialDurationSet = useRef(false);

  useEffect(() => {
    if (
      !isInitialDurationSet.current &&
      props.playlistById?.draft?.content?.list
    ) {
      const initialDurations = getInitialDurations(
        props.playlistById.draft.content.list
      );

      if (
        Object.keys(initialDurations).length > 0 &&
        Object.keys(customDurationUpdates).length === 0
      ) {
        setCustomDurationUpdates(initialDurations);
        isInitialDurationSet.current = true;
      }
    }
  }, [props.playlistById, customDurationUpdates]);

  useEffect(() => {
    if (props.playlistById) {
      subscribeToLiveUpdate();
      document.title = `${props.playlistById.name} - ${TitlePageSufix}`;

      setPlaylist(props.playlistById);
      if (isContentPropsUptodate(props, lastSaveAttempt) && !isDragging) {
        if (props.playlistById.draft) {
          setPlaylistItems(
            mappingActivePlaylistItems(props.playlistById.draft)
          );

          setDefaultDurationData(
            props.playlistById.draft?.content.props.default_durations
          );
        }
        setLastSaveAttempt("");
      }
    } else if (props.playlistById && playlistItems.length > 0) {
      setPlaylistItems([]);
    }

    return () => {
      cancelDebounceUpdate();
      unsubscribeFromLiveUpdate();
    };
  }, [
    props.playlistById?.draft,
    props.playlistById?.isSharedAll,
    props.playlistById?.sharedSpacesByPlaylistId,
  ]);

  useEffect(() => {
    const isFirstTimeDataLoaded = !playlist && isDataLoaded(props);
    const shouldOpenFullscreen =
      props.isFullScreen || (isFirstTimeDataLoaded && props.isFullScreen);

    if (shouldOpenFullscreen) {
      previewFullscreen();
    }

    const shouldCloseFullScreen = !props.isFullScreen;
    if (shouldCloseFullScreen) {
      context.modal.closeModals();
    }
  }, [props.location.pathname, props.isFullScreen]);

  const updatePlayList = async (
    playlistInput: UpdatePlaylistMutationVariables
  ) => {
    if (playlist?.draft) {
      setIsDraftSaving(true);
      await props.updatePlaylist({
        variables: playlistInput,
        update: (cache, { data }) => {
          if (data?.updatePlaylist?.playlist) {
            const formattedData = getFormattedPlaylistDataForCacheListing({
              playlistByIdForListCaching: props.playlistByIdForListCaching,
              playlistInput,
              updatedPlaylistData: data,
            });

            cache.writeFragment({
              id: cache.identify(data?.updatePlaylist?.playlist),
              data: formattedData,
              fragment: PlaylistListItemFragmentDoc,
              fragmentName: "PlaylistListItem",
            });
          }
        },
      });
      setIsDraftSaving(false);
      setIsEditing(false);
    }
  };

  if (!context.currentPermissions.validateCurrentSpace("playlist", "read")) {
    context.redirectToDefaultPath();
    return <Forbidden />;
  }

  const fetchAndSetPlaylistData = async (id: string) => {
    const fetchResult: ApolloQueryResult<PlaylistByIdQuery> = await props.client!.query(
      {
        ...refetchQueries(id),
        fetchPolicy: "network-only",
      }
    );

    if (fetchResult?.data?.playlistById?.draft) {
      if (!isEditing && isContentPropsUptodate(props, lastSaveAttempt)) {
        setLastSaveAttempt("");
        setPlaylistItems(
          mappingActivePlaylistItems(
            fetchResult.data.playlistById.draft as Playlist
          )
        );
      }
    }
  };

  const subscribeToLiveUpdate = () => {
    if (!unsubscribeLiveUpdateFn || !unsubscribeLiveUpdateFnDraft) {
      unsubscribeFromLiveUpdate();

      if (playlist) {
        unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
          context.user.claims.orgId || "",
          EntityType.PLAYLIST,
          playlist.id,
          () => {
            if (!isEditing || lastSaveAttempt !== "") {
              return;
            }
            fetchAndSetPlaylistData(playlist?.id);
          }
        );
      }

      if (playlist?.draft) {
        unsubscribeLiveUpdateFnDraft = subscribeToDocumentUpdates(
          context.user.claims.orgId || "",
          EntityType.PLAYLIST,
          playlist.draft.id,
          () => {
            if (!isEditing || lastSaveAttempt !== "") {
              return;
            }
            fetchAndSetPlaylistData(playlist?.draft?.id);
          }
        );
      }
    }
  };

  const unsubscribeFromLiveUpdate = () => {
    if (unsubscribeLiveUpdateFn) {
      unsubscribeLiveUpdateFn();
      unsubscribeLiveUpdateFn = undefined;
    }
    if (unsubscribeLiveUpdateFnDraft) {
      unsubscribeLiveUpdateFnDraft();
      unsubscribeLiveUpdateFnDraft = undefined;
    }
  };

  const previewFullscreen = () => {
    const { onPreviewPlaylistClick, playlistById } = props;
    if (isDataLoaded(props)) {
      const { id } = playlistById?.draft!;
      const isPlaylistOwner =
        context.user.settings.spaceId === playlistById?.spaceId;

      const playerPreview = (
        <PlayerPreview
          id={id}
          previewType={PreviewType.PLAYLIST}
          showExitFullScreenButton
          onExitFullScreenClicked={onPreviewPlaylistClick}
          isOwner={isPlaylistOwner}
        />
      );
      context.modal.openModal(playerPreview, null, {
        opts: {
          disableCloseButton: true,
          disableTitle: false,
          size: ModalSize.FULLSCREEN,
        },
      });
    }
  };

  const onClickMediaFileCallback = async (
    mediaId: string[],
    action: MediaFileActions,
    value: File[] | string | boolean
  ) => {
    switch (action) {
      case MediaFileActions.ADD:
        // add to conten item list
        onCreateFileDataItem(value as File[]);
        break;
      case MediaFileActions.VIEW:
        // show preview modal
        const mediaDetail = await context.modal.openMediaDetail({
          fileId: value[0]!.id,
        });
        if (mediaDetail) {
          updatePlaylistData();
        }
        break;
    }
  };

  const onClickMediaFolderCallback = (
    mediaId: string,
    action: MediaFolderActions,
    medias: File[]
  ) => {
    switch (action) {
      case MediaFolderActions.ADD:
        // add to conten item list
        onCreateFileDataItem(medias);
        break;
      default:
    }
  };

  const onClickSiteCallback = async (
    action: SiteListItemActions,
    value: Site | string | boolean
  ) => {
    switch (action) {
      case SiteListItemActions.ADD:
        // Add a content to playlist item
        onCreateSiteDataItem([value] as Site[]);
        break;
      case SiteListItemActions.PREVIEW:
        // show preview modal
        // TODO Display Site Detail Dialog
        break;
    }
  };

  const onClickLinkCallback = async (
    action: LinkListItemActions,
    value: Link | string | boolean
  ) => {
    switch (action) {
      case LinkListItemActions.ADD:
        // Add a content to playlist item
        onCreateLinkDataItem([value] as Link[]);
        break;
      case LinkListItemActions.PREVIEW:
        // show preview modal
        const linkDetail = await context.modal.openLinkDetail(
          (value as Link).id,
          ""
        );
        if (linkDetail) {
          updatePlaylistData();
        }
        break;
    }
  };

  const showMediaPicker = async (): Promise<void> => {
    const { mediaPicker } = getCurrentUserInterfaceVisibilitiesByContext(
      context
    );

    const menu: RefType[] = [RefType.FILE];
    if (mediaPicker.isShowLink) {
      menu.push(RefType.LINK);
    }
    // add app after link for the right menu order
    menu.push(RefType.CANVAS);
    menu.push(RefType.APP);

    if (mediaPicker.isShowDashboard) {
      menu.push(RefType.SITE);
    }
    const result = await context.modal.openMediaPicker(
      MediaPickerActionMode.ADD,
      "Media Picker",
      {
        menu,
        multiple: true,
        section: RefType.FILE,
        screenPickerAction: ScreenPickerActions.SET_CONTENT,
      }
    );

    switch (result.mediaType) {
      case RefType.FILE:
        await onCreateFileDataItem(result.data as File[]);
        break;
      case RefType.LINK:
        await onCreateLinkDataItem(result.data as Link[]);
        break;
      case RefType.SITE:
        await onCreateSiteDataItem(result.data as Site[]);
        break;
      case RefType.APP:
        await onCreateAppDataItem(result.data as AppInstance[]);
        break;
      default:
        return;
    }
  };

  const updatePlaylistContentItems = async (
    playlistInput: UpdatePlaylistMutationVariables
  ) => {
    if (playlist?.draft) {
      // no longer need optimistic update because the list content now cache in state
      setIsEditing(true);
      setLastSaveAttempt(md5(JSON.stringify(playlistInput.input.content)));
      debounceUpdatePlaylist(updatePlayList, playlistInput);
    }
  };

  const updateDefaultDuration = async (
    playlistInput: UpdatePlaylistMutationVariables
  ) => {
    if (playlist?.draft) {
      const newPlaylistItems = playlistItems.reduce((newList, list) => {
        switch (list.refType) {
          case RefType.FILE:
            list.duration =
              playlistInput.input.content.props.default_durations.image;
            break;
          case RefType.LINK:
            list.duration =
              playlistInput.input.content.props.default_durations.link;
            break;
          case RefType.SITE:
            list.duration =
              playlistInput.input.content.props.default_durations.site;
            break;
          case RefType.APP:
            list.duration =
              playlistInput.input.content.props.default_durations.app;
            break;
          case RefType.DOCUMENT:
            list.duration =
              playlistInput.input.content.props.default_durations.document;
            break;
          default:
            break;
        }

        return [...newList, list];
      }, []);
      setPlaylistItems(newPlaylistItems);
      setDefaultDurationData(
        playlistInput.input.content.props.default_durations
      );

      const refContents = onCreateContentRefObject(newPlaylistItems);

      const finalPlaylistInput: UpdatePlaylistMutationVariables = {
        input: {
          content: {
            ...playlist.draft.content,
            list: refContents,
            props: {
              ...playlist.draft.content.props,
              default_durations:
                playlistInput.input.content.props.default_durations,
              total_durations: getPlaylistItemsDuration(newPlaylistItems),
              total_items: newPlaylistItems.length,
            },
          },
          id: playlist.id,
        },
      };

      updatePlaylistContentItems(finalPlaylistInput);
    }
  };

  const onPlaylistColorChange = async (color: string) => {
    if (playlist?.draft?.filesByPlaylistId) {
      const playlistColorInput: UpdatePlaylistColorMutationVariables = {
        input: { id: playlist.id, color },
      };
      await props.updatePlaylistColor({
        variables: playlistColorInput,
      });
    }
  };

  const onFinishScrollTo = () => {
    setShouldScrollToItem("");
  };

  const getPlaylistContentItemByContentId = (
    id: Scalars["UUID"],
    type: RefType
  ) => {
    const draft = playlist?.draft;
    if (draft) {
      let item;
      switch (type) {
        case RefType.LINK:
          item =
            draft.linksByPlaylistId &&
            draft.linksByPlaylistId.nodes.find((n) => n!.id === id);
          break;
        case RefType.SITE:
          item =
            draft.sitesByPlaylistId &&
            draft.sitesByPlaylistId.nodes.find((n) => n!.id === id);
          break;
        case RefType.APP_INSTANCE:
        case RefType.APP:
          item =
            draft.appInstancesByPlaylistId &&
            draft.appInstancesByPlaylistId.nodes.find((n) => n!.id === id);
          break;
        default:
          item =
            draft.filesByPlaylistId &&
            draft.filesByPlaylistId.nodes.find((n) => n!.id === id);
          break;
      }
      return item;
    }
    return undefined;
  };

  const onShowAppconfigure = async (app: AppInstance) => {
    if (app) {
      const { success } = await context.modal.openAppConfigure(
        app.id,
        undefined,
        undefined,
        undefined,
        <FormattedMessage id="app.configure" defaultMessage="Configure" />
      );
      if (success) {
        updatePlaylistData();
      }
    }
  };

  const updatePlaylistData = async () => {
    const playlistResult: ApolloQueryResult<PlaylistByIdQuery> = await props.client!.query(
      {
        fetchPolicy: "network-only",
        query: PlaylistByIdDocument,
        variables: {
          id: playlist?.id,
        },
      }
    );

    if (playlistResult?.data?.playlistById?.draft) {
      setPlaylistItems(
        mappingActivePlaylistItems(playlistResult.data.playlistById.draft)
      );
    }
    setShouldRefetchingSidebar(true);
  };

  const onRefetchingFinish = () => {
    setShouldRefetchingSidebar(false);
  };

  const onContentActionChange = async (
    listId: string,
    contentId: string,
    action: PlaylistContentItemActions,
    contentIndex: number | undefined
  ) => {
    switch (action) {
      case PlaylistContentItemActions.PREVIEW:
        if (contentIndex !== undefined) {
          const playlistItem = playlistItems[contentIndex];
          const contentItem = getPlaylistContentItemByContentId(
            playlistItem.id,
            playlistItem.refType
          );
          switch (playlistItem.refType) {
            case RefType.LINK:
              const linkDetail = await context.modal.openLinkDetail(
                contentItem.id,
                ""
              );
              if (linkDetail) {
                updatePlaylistData();
              }
              break;
            case RefType.SITE:
              // TODO Display Site Detail Dialog
              break;
            case RefType.APP_INSTANCE:
            case RefType.APP:
              onShowAppconfigure(contentItem);
              break;
            default:
              if (contentItem) {
                const mediaDetail = await context.modal.openMediaDetail({
                  fileId: contentItem.id,
                });
                if (mediaDetail) {
                  updatePlaylistData();
                }
              }
              break;
          }
        }
        break;
      case PlaylistContentItemActions.DELETE:
        if (playlist?.draft) {
          const newPlaylistItems = playlistItems.filter(
            (_, index) => index !== contentIndex
          );
          setPlaylistItems(newPlaylistItems);
          const refContentsData = onCreateContentRefObject(newPlaylistItems);
          updatePlaylistContent(refContentsData);
        }
        break;
      default:
        break;
    }
  };

  const updatePlaylistItemsWithCustomDurations = () => {
    return playlistItems.map((item) => ({
      ...item,
      customDuration:
        customDurationUpdates[item.list_id] ?? item.customDuration,
    }));
  };

  const saveUpdatedPlaylistItems = () => {
    const updatedPlaylistItems = updatePlaylistItemsWithCustomDurations();
    const refContentsData = onCreateContentRefObject(updatedPlaylistItems);
    updatePlaylistContent(refContentsData);
  };

  const onCustomDurationChange = (duration: number, listId: string) => {
    setCustomDurationUpdates((prev) => ({ ...prev, [listId]: duration }));
    saveUpdatedPlaylistItems();
  };

  const onDurationFocus = (isFocus: boolean) => {
    if (!isFocus) {
      saveUpdatedPlaylistItems();
    }
  };

  const updatePlaylistContentWithDebounce = async (
    updatedPlaylistItems?: ActivePlaylistItem[]
  ) => {
    cancelDebounceUpdateCustomDuration();
    debounceUpdateCustomDuration = setTimeout(() => {
      const refContentsData = onCreateContentRefObject(
        updatedPlaylistItems || playlistItems
      );
      updatePlaylistContent(refContentsData);
    }, DEBOUNCE_TIMEOUT_MS);
  };

  const updatePlaylistContent = async (lists: ListContentItem[]) => {
    if (playlist?.draft) {
      const playlistInput: UpdatePlaylistMutationVariables = {
        input: {
          content: {
            ...playlist.draft.content,
            list: lists,
            props: {
              ...playlist.draft.content.props,
              default_durations: defaultDurationData,
              total_durations: getPlaylistItemsDuration(playlistItems),
              total_items: playlistItems.length,
            },
          },
          id: playlist.id,
        },
      };

      updatePlaylistContentItems(playlistInput);
    }
  };

  const selectedMediaFileCallback = (media: File | Folder) => {
    setSelectedMediaItem(media);
  };

  const selectedLinkCallback = (link: Link) => {
    setSelectedLinkItem(link);
  };

  const selectedSiteCallback = (site: Site) => {
    setSelectedSiteItem(site);
  };

  const addPlaylistContentItem = (
    newPlaylistItems: ActivePlaylistItem[],
    index?: number
  ) => {
    newPlaylistItems = removeEmptyFromArray(newPlaylistItems); // hack, need to improve logic
    const scrollToItem =
      index !== undefined
        ? ""
        : newPlaylistItems[newPlaylistItems.length - 1].list_id;
    let addedPlaylistItems;

    if (index && index > 0) {
      // Adding a item by drag from sidebar
      addedPlaylistItems = [
        ...playlistItems.slice(0, index),
        ...newPlaylistItems,
        ...playlistItems.slice(index),
      ];
    } else if (index === 0) {
      // Adding new item by drag and drop the item in the first place of playlist
      addedPlaylistItems = [...newPlaylistItems, ...playlistItems];
    } else {
      // Adding a new item by on click content item
      addedPlaylistItems = [...playlistItems, ...newPlaylistItems];
    }
    setPlaylistItems(addedPlaylistItems);
    setSelectedLinkItems([]);
    setSelectedSiteItems([]);
    setSelectedMediaItems([]);
    setShouldScrollToItem(scrollToItem);

    const refContents = onCreateContentRefObject(addedPlaylistItems);
    updatePlaylistContent(refContents);
  };

  const onClickAppInstanceCallback = (
    app: AppInstance,
    action: AppTabPaneItemActions,
    iconUrl?: string
  ) => {
    switch (action) {
      case AppTabPaneItemActionEnum.CLICK:
        onCreateAppDataItem([app] as AppInstance[], undefined);
        break;
      case AppTabPaneItemActionEnum.DRAG:
        setSelectedAppItem(app);
        break;
      case AppTabPaneItemActionEnum.PREVIEW:
        updatePlaylistData();
        break;
    }
  };

  const getAllFilesFromFolderId = async (folderId: string): Promise<File[]> => {
    const nestedFilesByFolderIdQuery: ApolloQueryResult<NestedFilesByFolderIdQuery> = await props.client!.query(
      {
        query: NestedFilesByFolderIdDocument,
        fetchPolicy: "network-only",
        variables: {
          folderId,
        },
      }
    );
    const files = nestedFilesByFolderIdQuery.data.nestedFilesByFolderId
      ?.nodes as File[];
    return sortedByName(files);
  };

  const onCreateFileDataItem = async (
    files: File[],
    index?: number,
    folderName?: string
  ) => {
    const fileLength = files.length;
    if (files.length > 20) {
      const confirmationMessage = (
        <FormattedHTMLMessage
          id="playlists.add_folder_confirmation"
          defaultMessage="You are about to add <strong>{fileLength}</strong> items from the {folderName} folder into this playlist."
          values={{
            fileLength,
            folderName,
          }}
        />
      );
      const { confirm } = await context.modal.confirm(confirmationMessage, {
        confirm: (
          <FormattedMessage
            id="common.text.continue_anyway"
            defaultMessage="Continue Anyway"
          />
        ),
        cancel: (
          <FormattedMessage id="common.text.cancel" defaultMessage="Cancel" />
        ),
      });

      if (!confirm) {
        return;
      }
    }

    if (playlist?.draft && files?.length > 0) {
      const { content } = playlist.draft;
      let duration = DEFAULT_GLOBAL_DURATION;
      if (
        files[0] &&
        isDocument(files[0]) &&
        files[0].fileOutputsByFileId &&
        content.props.default_durations.document
      ) {
        duration = content.props.default_durations.document;
      } else if (content.props.default_durations.image) {
        duration = content.props.default_durations.image;
      }
      const newPlaylistItem = fileToPlaylistContentMapping(files, duration);
      addPlaylistContentItem(newPlaylistItem, index);
    }
  };

  const onCreateLinkDataItem = (links: Link[], index?: number): void => {
    if (playlist?.draft) {
      const { content } = playlist.draft;
      const duration = content.props.default_durations.link
        ? content.props.default_durations.link
        : DEFAULT_GLOBAL_DURATION;
      const newPlaylistItem = linkToPlaylistContentMapping(links, duration);

      addPlaylistContentItem(newPlaylistItem, index);
    }
  };

  const onCreateSiteDataItem = (sites: Site[], index?: number): void => {
    if (playlist?.draft) {
      const { content } = playlist.draft;
      const duration = content.props.default_durations.site
        ? content.props.default_durations.site
        : DEFAULT_GLOBAL_DURATION;
      const newPlaylistItem = siteToPlaylistContentMapping(sites, duration);

      addPlaylistContentItem(newPlaylistItem, index);
    }
  };

  const onCreateAppDataItem = (apps: AppInstance[], index?: number) => {
    if (playlist?.draft) {
      const { content } = playlist.draft;

      // TODO: This is a fixed for #9967 which can't be test locally. refactor this after things works fine.
      const newPlaylistItem = apps.map((app) => {
        const duration =
          getAppInstanceDuration(app) ??
          content.props.default_durations.app ??
          DEFAULT_GLOBAL_DURATION;
        return {
          id: app.id,
          list_id: uuidv4(),
          dragableId: app.id + Math.random(),
          name: app.name,
          refType: RefType.APP,
          customDuration: 0,
          duration,
          mimeType: app.__typename,
          isFixedDuration: Boolean(app?.config?.appInstanceDurationInSeconds),
        };
      });
      addPlaylistContentItem(newPlaylistItem, index);
    }
  };

  const onDragStart = async () => {
    setIsDragging(true);
    cancelDebounceUpdate();
  };

  const onDragEnd = async (dropResult: DropResult) => {
    setIsDragging(false);
    if (!dropResult.destination) {
      return;
    }
    const source: DraggableLocation = dropResult.source;
    const destination: DraggableLocation = dropResult.destination;

    if (source.droppableId === destination.droppableId) {
      // Move an item inside playlist item area (sort by hand)
      const swapPlaylistItems = playlistItems.filter(
        (_, index) => index !== source.index
      );
      const [removed] = playlistItems.splice(source.index, 1);
      swapPlaylistItems.splice(destination.index, 0, removed);
      setPlaylistItems(swapPlaylistItems);
      if (destination.index !== source.index) {
        const refContents = onCreateContentRefObject(swapPlaylistItems);
        updatePlaylistContent(refContents);
      }
    } else {
      switch (source.droppableId.split("-")[0]) {
        // Detect type of item by dragging from sidebar
        case RefType.LINK:
          if (selectedLinkItems.length > 0) {
            onCreateLinkDataItem(selectedLinkItems, destination.index);
          } else if (selectedLinkItem) {
            onCreateLinkDataItem([selectedLinkItem], destination.index);
          }
          break;
        case RefType.SITE:
          if (selectedSiteItems.length > 0) {
            onCreateSiteDataItem(selectedSiteItems, destination.index);
          } else if (selectedSiteItem) {
            onCreateSiteDataItem([selectedSiteItem], destination.index);
          }
          break;
        case RefType.FILE:
          // multiple select for media files
          if (selectedMediaItems.length > 1) {
            onCreateFileDataItem(selectedMediaItems, destination.index);
          } else {
            if (selectedMediaItem) {
              if (selectedMediaItem.__typename === "Folder") {
                const folderSelected = selectedMediaItem as Folder;
                const files = await getAllFilesFromFolderId(folderSelected.id);
                if (files.length > 0) {
                  onCreateFileDataItem(
                    files,
                    destination.index,
                    folderSelected.name
                  );
                }
              } else {
                const selectedFile = selectedMediaItem as File;
                if (selectedFile.fileProcessingStatus !== JobStatus.Failed) {
                  onCreateFileDataItem(
                    [selectedMediaItem as File],
                    destination.index
                  );
                }
              }
            }
          }
          break;
        case RefType.APP:
          if (selectedAppItem) {
            onCreateAppDataItem([selectedAppItem], destination.index);
          }
          break;
        default:
      }
    }
  };

  const onDiscardChanges = async () => {
    if (playlist) {
      const playlistInput: RevertDraftPlaylistMutationVariables = {
        input: {
          id: playlist.id,
        },
      };
      props.revertDraftPlaylist({
        variables: playlistInput,
        refetchQueries: [refetchQueries(playlist.id)],
      });
    }
  };

  const onDeletePlaylist = async () => {
    if (playlist?.draft) {
      const firstScreenName = playlist.castedScreenByPlaylistId!.totalCount
        ? playlist.castedScreenByPlaylistId!.nodes[0]!.name
        : "";
      const formattedMessageValue = {
        name: <strong>{playlist.name}</strong>,
        screenCount: playlist.castedScreenByPlaylistId!.totalCount,
        screenName: <strong>{firstScreenName}</strong>,
      };
      const { confirm } = await context.modal.confirm(
        <>
          <h2>
            <FormattedMessage
              id="ui_component.confirm.delete_heading_name"
              defaultMessage="Delete {name}?"
              values={formattedMessageValue}
            />
          </h2>
          <p>
            <FormattedMessage
              id="ui_component.confirm.delete_message_with_cast"
              defaultMessage="{screenCount, select, 0 {{name} will be deleted permanently and this action cannot be undone.} 1 {{name} is currently casting on {screenName}. Are you sure you want to continue? This action cannot be undone.} other {{name} is currently casting on {screenCount} screens, Are you sure you want to continue? This action cannot be undone.}}"
              values={formattedMessageValue}
            />
          </p>
        </>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        }
      );
      if (confirm) {
        await props.deletePlaylist({ playlistId: playlist.id });
        context.history.replace("/playlists");
      }
    }
  };

  const onDuplicatePlayList = () => {
    if (playlist) {
      const duplicatePlaylistInput: DuplicatePlaylistMutationVariables = {
        input: {
          id: playlist.id,
          name: getDuplicateInstanceName(playlist.name),
        },
      };

      props
        .duplicatePlaylist({
          variables: duplicatePlaylistInput,
          update: (cache, { data }) => {
            if (data?.duplicatePlaylist?.playlist) {
              updateNewPlaylistToList(
                context,
                cache,
                data.duplicatePlaylist.playlist
              );
            }
          },
        })
        .then((result) => {
          const data = result && result.data;
          if (
            data &&
            data.duplicatePlaylist &&
            data.duplicatePlaylist.playlist !== null
          ) {
            context.history.push(
              "/playlists/" + data.duplicatePlaylist.playlist.id + "?new=true"
            );
          }
        });
    }
  };

  const onCleanupPlaylist = () => {
    if (props.playlistById && props.playlistById.draft) {
      const validPlaylistItems = getValidPlaylistItems(playlistItems);
      setPlaylistItems(validPlaylistItems);
      updatePlaylistContentWithDebounce(validPlaylistItems);
    }
  };

  const checkAndUpdatePlaylistContent = async () => {
    const refContents = onCreateContentRefObject(playlistItems);
    if (
      refContents.length !== props.playlistById?.draft?.content?.list.length &&
      playlist?.draft
    ) {
      const playlistInput: UpdatePlaylistMutationVariables = {
        input: {
          content: {
            ...playlist.draft.content,
            list: refContents,
            props: {
              ...playlist.draft.content.props,
              total_durations: getPlaylistItemsDuration(playlistItems),
              total_items: playlistItems.length,
            },
          },
          id: playlist.id,
        },
      };
      return props.updatePlaylist({
        variables: playlistInput,
      });
    } else {
      return Promise.resolve();
    }
  };

  const onOpenMediaPicker = async () => {
    if (props.playlistById?.draft) {
      const isAbleToViewLink = context.currentPermissions.validateCurrentSpace(
        "link",
        "read"
      );
      const isAbleToViewSite = context.currentPermissions.validateCurrentSpace(
        "site",
        "read"
      );
      const isAbleToViewMedia = context.currentPermissions.validateCurrentSpace(
        "media",
        "read"
      );
      const isAbleToViewApp = context.currentPermissions.validateCurrentSpace(
        "app_instance",
        "read"
      );

      const menu: RefType[] = isAbleToViewMedia ? [RefType.FILE] : [];
      if (
        isAbleToViewLink &&
        context.shouldShowFeature(FEATURE_FLAGS_ENUM.LINKS_MENU)
      ) {
        menu.push(RefType.LINK);
      }
      // add app after link for the right menu order
      isAbleToViewApp && menu.push(RefType.CANVAS);
      isAbleToViewApp && menu.push(RefType.APP);
      if (
        isAbleToViewSite &&
        context.shouldShowFeature(FEATURE_FLAGS_ENUM.SITES_MENU)
      ) {
        menu.push(RefType.SITE);
      }
      const result = await context.modal.openMediaPicker(
        MediaPickerActionMode.ADD,
        "Media Picker",
        {
          menu,
          multiple: true,
          screenPickerAction: ScreenPickerActions.SET_CONTENT,
        }
      );
      if (result.mediaType) {
        switch (result.mediaType) {
          case RefType.LINK:
            onCreateLinkDataItem(result.data as Link[]);
            break;
          case RefType.SITE:
            onCreateSiteDataItem(result.data as Site[]);
            break;
          case RefType.FILE:
            onCreateFileDataItem(result.data as File[]);
            break;
          case RefType.APP:
            onCreateAppDataItem(result.data as AppInstance[]);
            break;
          default:
            break;
        }
      }
    }
  };

  const onPublishPlaylist = async () => {
    if (!isPublishing) {
      if (playlist) {
        if (context.currentOrg?.preferences?.settings?.confirm_before_publish) {
          const confirmationMessage = (
            <>
              <div>
                <strong>
                  <FormattedMessage
                    id="ui_component.confirm.publish_change_header"
                    defaultMessage="Publish changes?"
                  />
                </strong>
              </div>
              <div style={{ padding: "10px 0 0 0" }}>
                <FormattedMessage
                  id="ui_component.confirm.publish_confirm_message"
                  defaultMessage="Any screens currently playing this {name} will restart playback from the beginning."
                  values={{ name: "playlist" }}
                />
              </div>
            </>
          );
          const confirmPublishing = await context.modal.confirm(
            confirmationMessage,
            {
              confirm: (
                <FormattedMessage
                  id="common.button.publish_now"
                  defaultMessage="Publish now"
                />
              ),
              cancel: (
                <FormattedMessage
                  id="common.button.leave_as_draft"
                  defaultMessage="Leave {name} as draft"
                  values={{ name: "playlist" }}
                />
              ),
            }
          );

          if (confirmPublishing.confirm) {
            publishPlaylist();
          }
        } else {
          publishPlaylist();
        }
      }
    }
  };

  const publishPlaylist = async () => {
    if (playlist?.id) {
      const publishInput: PublishDraftPlaylistMutationVariables = {
        input: {
          id: playlist.id,
        },
      };

      setIsPublishing(true);

      await checkAndUpdatePlaylistContent();
      props
        .publishDraftPlaylist({
          variables: publishInput,
        })
        .then(() => {
          setIsHidePublishAnimation(true);
          setIsPublishing(false);
          window.setTimeout(() => {
            // animation delaying after publish mutation for 0.7 seconds
            setIsHidePublishAnimation(false);
          }, 700);
        });
    }
  };

  const onUpdatePlaylistTags = async (tags: string[]) => {
    if (playlist?.draft) {
      const playlistTagsInput: UpdatePlaylistTagsMutationVariables = {
        input: {
          id: playlist.id,
          tags,
        },
      };
      const _playlist = cloneDeep(playlist);
      _playlist.tags = tags;
      setIsDraftSaving(true);
      setPlaylist(_playlist);
      await props.updatePlaylistTags({
        update: (proxy, updateData: { data: UpdatePlaylistTagsMutation }) => {
          // Read the data from our cache for this query.
          const cacheAllTags = proxy.readQuery<AllTagsQuery>({
            query: AllTagsDocument,
          });
          const cloneAllTags = cloneDeep(cacheAllTags);
          const newPlaylistData = updateData.data;

          if (
            newPlaylistData?.updatePlaylistTags?.playlist?.tags &&
            cloneAllTags?.allTags
          ) {
            const updatedTags = cloneDeep(
              newPlaylistData.updatePlaylistTags.playlist.tags
            );
            const existingTags = cloneAllTags.allTags.nodes;

            const updatedCacheAllTags = appendNewTags(
              context.user.claims.orgId,
              context.currentSpace?.id,
              updatedTags,
              existingTags
            );
            cloneAllTags!.allTags.nodes = (updatedCacheAllTags ?? []).map(
              (tag) => {
                return {
                  ...tag,
                  __typename: "Tag" as "Tag",
                  orgId: tag!.orgId!,
                  name: tag!.name!,
                };
              }
            );
            proxy.writeQuery({ query: AllTagsDocument, data: cloneAllTags });
          }
        },
        variables: playlistTagsInput,
      });
      setIsDraftSaving(false);
    }
  };

  const onUpdatePlaylistOption = (
    action: PlaylistOptionAction,
    data?: PlaylistOptionDataType
  ) => {
    switch (action) {
      case PlaylistOptionActionEnum.COLOR:
        onPlaylistColorChange(data as string);
        break;
      case PlaylistOptionActionEnum.DEFAULT_DURATION:
        if (playlist?.draft) {
          const content = { ...playlist.draft.content };
          const defaultDurations = data; // { ...content.props.default_durations }
          const props = {
            ...content.props,
            default_durations: defaultDurations,
            total_durations: getPlaylistItemsDuration(playlistItems),
          };
          content.props = props;
          const playlistInput = {
            input: {
              content,
              id: playlist.id,
            },
          };
          updateDefaultDuration(playlistInput);
        }
        break;
      case PlaylistOptionActionEnum.DELETE:
        onDeletePlaylist();
        break;
      case PlaylistOptionActionEnum.DISCARD:
        onDiscardChanges();
        break;
      case PlaylistOptionActionEnum.DUPLICATE:
        onDuplicatePlayList();
        break;
      case PlaylistOptionActionEnum.TAG:
        if (data) {
          onUpdatePlaylistTags(data as string[]);
        }
        break;
      default:
        break;
    }
  };

  const renderHeader = () => {
    const {
      mode,
      onEditPlaylistClick,
      onPreviewPlaylistFromDetailPageClick,
      onGoBackToPlaylistListPage,
      onPreviewFullscreen,
    } = props;

    if (!playlist) {
      return null;
    }
    return (
      <PlaylistDetailHeader
        onDiscardChanges={onDiscardChanges}
        mode={mode}
        playlist={playlist as Playlist}
        isHidePublishAnimation={isHidePublishAnimation}
        isPublishing={isPublishing}
        isDraftSaving={isDraftSaving}
        shouldDisplayCleanupButton={
          playlistItems && doesContainExpiredPlaylistItems(playlistItems)
        }
        onPublishPlaylist={onPublishPlaylist}
        refetchQueries={refetchQueries}
        onDeletePlaylist={onDeletePlaylist}
        onEditPlaylistClick={onEditPlaylistClick}
        onPreviewPlaylistClick={onPreviewPlaylistFromDetailPageClick}
        onGoBackToPlaylistListPage={onGoBackToPlaylistListPage}
        onPreviewFullscreen={onPreviewFullscreen}
        onDuplicatePlayList={onDuplicatePlayList}
        onCleanupPlaylist={onCleanupPlaylist}
      />
    );
  };

  const renderEditMode = () => {
    const context = useAppContext();
    if (playlist) {
      const allTags = (props.allTags?.nodes ?? []).map((item) => ({
        text: item.name,
        value: item.name,
      }));
      const content = playlist?.draft?.content;
      const tags: string[] = playlist.tags ? (playlist.tags as string[]) : [];
      const canUpdatePlaylist = canBeUpdated({ context, shareable: playlist });
      return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Styled className="playlist-detail">
            <Main>
              {renderHeader()}

              <PageContainer className="wrapper">
                <Container sidebar={true} className="container">
                  <div className="playlist-content-options">
                    <div
                      className="total-duration"
                      data-testid="total-duration"
                    >
                      <FormattedMessage
                        id="playlists.total_duration"
                        defaultMessage="Total Duration"
                      />
                      <h3 data-testid="total-duration-time">
                        {getTotalTimeDuration(
                          getPlaylistItemsDuration(playlistItems)
                        )}
                      </h3>
                    </div>

                    {canUpdatePlaylist && (
                      <Button
                        data-testid="add-content-button"
                        className={`btn-add-content`}
                        onClick={showMediaPicker}
                        disabled={
                          !isOwner({ context, spaceId: playlist.spaceId })
                        }
                      >
                        <Icon name="plus-circle" />
                        <FormattedMessage
                          id="playlists.playlist_content_empty.add_content"
                          defaultMessage="Add Content"
                        />
                      </Button>
                    )}
                  </div>
                  <PlaylistContentList
                    key={playlist.draft?.content}
                    isDragDisabled={!canUpdatePlaylist}
                    isEditable={canUpdatePlaylist as boolean}
                    playlists={playlistItems}
                    onActionChange={onContentActionChange}
                    onDurationChange={onCustomDurationChange}
                    onDurationFocus={onDurationFocus}
                    shouldScrollToItem={shouldScrollToItem}
                    onFinishScrollTo={onFinishScrollTo}
                    onContentPick={showMediaPicker}
                    customDuration={customDurationUpdates}
                  />
                </Container>

                <SidebarRight
                  className="sidebar-right sidebar-playlist"
                  data-testid="sidebar-right"
                >
                  <Tab
                    className="sidebar-tab"
                    menu={{ secondary: true, pointing: true }}
                    center={true}
                    data-testid="tab-navigation"
                    panes={[
                      {
                        menuItem: context.intl.formatMessage({
                          id: "common.content",
                          defaultMessage: "Content",
                        }),
                        render: () => (
                          <MediaSidebar
                            isEditable={canUpdatePlaylist}
                            selectedMediaItems={selectedMediaItems}
                            onSelectMediaItems={setSelectedMediaItems}
                            selectedLinkItems={selectedLinkItems}
                            onSelectLinkItems={setSelectedLinkItems}
                            onClickLinkCallback={onClickLinkCallback}
                            onClickMediaFileCallback={onClickMediaFileCallback}
                            onClickMediaFolderCallback={
                              onClickMediaFolderCallback
                            }
                            selectedMediaFileCallback={
                              selectedMediaFileCallback
                            }
                            selectedLinkCallback={selectedLinkCallback}
                            onClickAppInstanceCallback={
                              onClickAppInstanceCallback
                            }
                            shouldRefetchingSidebar={shouldRefetchingSidebar}
                            onRefetchingFinish={onRefetchingFinish}
                            selectedSiteItems={selectedSiteItems}
                            onSelectSiteItems={setSelectedSiteItems}
                            selectedSiteCallback={selectedSiteCallback}
                            onClickSiteCallback={onClickSiteCallback}
                            onOpenMediaPicker={onOpenMediaPicker}
                          />
                        ),
                      },
                      {
                        menuItem: context.intl.formatMessage({
                          id: "common.settings",
                          defaultMessage: "Settings",
                        }),
                        render: () => (
                          <PlaylistOptions
                            isEditable={canUpdatePlaylist as boolean}
                            onUpdatePlaylistOption={onUpdatePlaylistOption}
                            tags={tags}
                            tagsList={allTags}
                            content={content}
                            color={playlist.color!}
                          />
                        ),
                      },
                    ]}
                    totaltab={2}
                  />
                </SidebarRight>
              </PageContainer>
            </Main>
          </Styled>
        </DragDropContext>
      );
    } else {
      return <LoaderBar />;
    }
  };

  const renderPreviewMode = () => {
    if (playlist) {
      const canUpdate = canBeUpdated({ context, shareable: playlist });
      const canPublish = canBePublished({ context, playlist });
      return (
        <Styled className="playlist-detail">
          <Main>
            {(canUpdate || canPublish) && renderHeader()}
            {renderPlayerPreview(props, context, playlist)}
          </Main>
        </Styled>
      );
    } else {
      return <div />;
    }
  };

  return props.mode === PlaylistDetailMode.Preview
    ? renderPreviewMode()
    : renderEditMode();
};

export default withData(PlaylistDetail) as React.ComponentType<{}>;
