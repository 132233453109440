import { appConfig } from "src/appConfig";
import { getOrgRegionFromUrl } from "src/helpers/urlHelper";

export const createQuickPostContent = async (
  signedToken: string | null | undefined,
  hookId: string
): Promise<void> => {
  const content = [
    {
      content: {
        title: {
          content: "SUMMER SOCIAL",
        },
        body: {
          content: "<p><span>August 25th @ 7pm, Ryandale Steakhouse</span></p>",
        },
      },
      attachments: [],
    },
    {
      content: {
        title: {
          content: "FIRE SAFETY TRAINING",
        },
        body: {
          content:
            "<p><span>September 12th 9.30am - 3.30pm</span></p><p><span>Lunch will be provided.</span></p>",
        },
      },
      attachments: [],
    },
    {
      content: {
        title: {
          content: "",
        },
        body: {
          content:
            "<p><strong>REMINDER </strong></p><p><span>A health &amp; safety inspection of this area will be conducted on July 24th at 3pm.</span></p><br><p><span>A H&amp;S officer will organise a meeting with each team prior to this, followed by an action plan for all.</span></p><br><p><span>Thank you.</span></p><br>",
        },
      },
      attachments: [],
    },
  ];

  const createFeedItemRes = await fetch(
    appConfig.platformFeedsUrl + `/v1/hooks/${hookId}?appName=quickpost`,
    {
      body: JSON.stringify({
        items: content,
      }),
      headers: {
        Authorization: `Bearer ${signedToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );

  if (!createFeedItemRes.ok) {
    throw new Error(
      `Failed to create feed item: ${createFeedItemRes.statusText}`
    );
  }
};

export const createQuickPostConfig = async (
  signedToken: string | null | undefined
): Promise<{
  feedId: string;
  hookId: string;
  region: string;
  appConfigId: string;
  stylePackName: string;
}> => {
  const stylePackName = "angular";

  const createAppConfigRes = await fetch(
    appConfig.platformConnectionsUrl + `/create-app-config?appName=quickpost`,
    {
      headers: {
        Authorization: `Bearer ${signedToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );

  if (!createAppConfigRes.ok) {
    throw new Error(
      `Failed to create app config: ${createAppConfigRes.statusText}`
    );
  }

  const appConfigId = (await createAppConfigRes.json()).data.appConfigId;
  const createHookRes = await fetch(appConfig.platformFeedsUrl + `/hooks`, {
    body: JSON.stringify({
      appName: "quickpost",
      provider: "Webhook",
      appConfigId,
    }),
    headers: {
      Authorization: `Bearer ${signedToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  if (!createHookRes.ok) {
    throw new Error(`Failed to create hook: ${createHookRes.statusText}`);
  }

  const createHookResJson = await createHookRes.json();
  const hookId = createHookResJson.data.hookId;
  const feedId = createHookResJson.data.feedId;

  const updateFeedConfigRes = await fetch(
    appConfig.platformFeedsUrl + `/feed?appName=quickpost`,
    {
      body: JSON.stringify({
        config: {
          stylePackName,
        },
        feedId,
      }),
      headers: {
        Authorization: `Bearer ${signedToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
    }
  );

  if (!updateFeedConfigRes.ok) {
    throw new Error(
      `Failed to update feed config: ${updateFeedConfigRes.statusText}`
    );
  }

  return {
    feedId,
    hookId,
    region: getOrgRegionFromUrl().isEu ? "eu" : "us",
    appConfigId,
    stylePackName,
  };
};
