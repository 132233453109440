import { UUID } from "@screencloud/uuid";
import {
  CreateOnboardingPlaylistMutation,
  CreateOnboardingPlaylistMutationVariables,
  CreateOnboardingPlaylistDocument,
} from "src/types.g";
import apolloClient from "src/state/apolloClient";
import { OnboardingFile, createFiles } from "../entities/file";
import { CotentRefObject, createObjectReferenceByType } from "../execute";
import { RefType } from "src/constants/constants";
import {
  OnboardingAppInstance,
  installAppAndAppInstances,
} from "../entities/app";
import { BackgroundImages } from "../config/media";
import {
  PlaylistMetaProps,
  Playlists,
  playlistWithMeta,
} from "../config/playlists";
import { createCanvases } from "../entities/canvas";
import { BrandInfo } from "../entities/brand";

type CreatePlaylistProps = {
  name: string;
  spaceId: UUID;
  content: { list: any[]; props: Record<string, any> };
  color?: string;
};

type BaseParams = {
  spaceId: UUID;
  orgName: string;
  brandInfo: BrandInfo | undefined;
  countryCode: string;
  rootFolderId?: UUID;
};

export const createOnboardingPlaylist = async ({
  name,
  spaceId,
  content,
  color,
}: CreatePlaylistProps) => {
  const variables: CreateOnboardingPlaylistMutationVariables = {
    input: {
      autoPublish: true,
      color,
      content,
      name,
      spaceId,
    },
  };
  const { data } = await apolloClient.mutate<
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables
  >({
    mutation: CreateOnboardingPlaylistDocument,
    variables,
  });
  return data?.createPlaylist?.playlist;
};

const createPlaylistContents = async ({
  playlistMetaProps,
  spaceId,
  orgName,
  brandInfo,
  countryCode,
  folderId,
}: {
  playlistMetaProps: PlaylistMetaProps;
  spaceId: UUID;
  orgName: string;
  brandInfo: BrandInfo | undefined;
  countryCode: string;
  folderId?: UUID;
}) => {
  const appsToBeInstalled = playlistMetaProps.content.apps;
  const canvasesToBeInstalled = playlistMetaProps.content.canvases;
  const imagesToBeInstalled = playlistMetaProps.content.images;
  const imagesWithMeta = imagesToBeInstalled.map((image) => {
    return BackgroundImages[image];
  });

  const [
    installedAppIntances,
    installedCanvases,
    installedImages,
  ] = await Promise.all([
    appsToBeInstalled.length > 0
      ? installAppAndAppInstances(appsToBeInstalled, spaceId, countryCode)
      : null,
    canvasesToBeInstalled.length > 0
      ? createCanvases({
          canvases: canvasesToBeInstalled,
          spaceId,
          orgName,
          brandInfo,
        })
      : null,
    imagesWithMeta.length > 0
      ? createFiles({ spaceId, files: imagesWithMeta, folderId })
      : null,
  ]);

  return {
    installedAppIntances,
    installedCanvases,
    installedImages,
  };
};

const createPlaylistContentsRefObject = (
  installedContents: {
    content: OnboardingAppInstance[] | OnboardingFile[];
    type: RefType;
  }[]
) => {
  const objectRef: CotentRefObject[] = [];

  installedContents.forEach((item) => {
    item.content.forEach((content) => {
      objectRef.push(
        createObjectReferenceByType({
          id: content?.id,
          type: item.type,
        })
      );
    });
  });

  return objectRef;
};

const createEmployeeRecognitionPlaylist = async ({
  spaceId,
  orgName,
  countryCode,
  brandInfo,
}: BaseParams) => {
  const employeeRecognitionPlaylistData = playlistWithMeta(
    Playlists.EMPLOYEE_RECOGNITION
  );
  const { installedCanvases } = await createPlaylistContents({
    playlistMetaProps: employeeRecognitionPlaylistData,
    spaceId,
    orgName,
    countryCode,
    brandInfo,
  });

  if (!installedCanvases) {
    return null;
  }

  const refObject = createPlaylistContentsRefObject([
    { content: installedCanvases, type: RefType.APP },
  ]);

  const playlist = await createOnboardingPlaylist({
    name: employeeRecognitionPlaylistData.name,
    spaceId,
    content: {
      list: [...refObject],
      props: employeeRecognitionPlaylistData.props,
    },
  });

  return playlist;
};

const createPlaylistByPlaylistName = ({
  playlistName,
  spaceId,
  orgName,
  countryCode,
  brandInfo,
}: BaseParams & { playlistName: Playlists }) => {
  const params = {
    spaceId,
    orgName,
    brandInfo,
    countryCode,
  };
  switch (playlistName) {
    case Playlists.EMPLOYEE_RECOGNITION:
      return createEmployeeRecognitionPlaylist(params);
    default:
      return null;
  }
};

export const createPlaylists = async ({
  playlistNames,
  spaceId,
  orgName,
  brandInfo,
  countryCode,
}: BaseParams & { playlistNames: Playlists[] }) => {
  const playlists = await Promise.all(
    playlistNames.map((playlistName) => {
      return createPlaylistByPlaylistName({
        playlistName,
        spaceId,
        orgName,
        brandInfo,
        countryCode,
      });
    })
  );
  return playlists;
};
