import { EMAIL_DOMAIN_LIST } from "src/constants/constants";
import { getContrastYIQ } from "src/helpers/whiteLabel";
import apolloClient from "src/state/apolloClient";
import {
  GetBrandInfoQuery,
  GetBrandInfoQueryVariables,
  GetBrandInfoDocument,
  GetBrandInfoPayload,
} from "src/types.g";

export type BrandInfo = {
  name: string;
  domain: string;
  claimed: boolean;
  description: string;
  links: { name: string; url: string }[];
  logos: {
    type: string;
    theme: string;
    formats: {
      src: string;
      background: string;
      format: string;
      size: number;
      height: number;
      width: number;
    }[];
  }[];
  colors: { hex: string; type: string; brightness: number }[];
  fonts: { name: string; type: string; origin: string; originId: string }[];
  images: {
    type: string;
    formats: {
      src: string;
      background: string;
      format: string;
      height: number;
      width: number;
      size: number;
    }[];
  }[];
};

export const toBrandInfo = (payload: GetBrandInfoPayload) => {
  if (payload === null) {
    return undefined;
  }
  return {
    name: payload!.name!,
    domain: payload?.name!,
    claimed: payload?.claimed!,
    description: payload?.description!,
    links: payload!.links!.map((data) => ({
      name: data?.name as string,
      url: data?.url as string,
    })),
    logos: payload!.logos!.map((logo) => ({
      type: logo!.type as string,
      theme: logo!.theme as string,
      formats: logo!.formats!.map((fmt) => ({
        src: fmt?.src as string,
        background: fmt?.background as string,
        format: fmt?.format as string,
        size: fmt?.size as number,
        height: fmt?.height as number,
        width: fmt?.width as number,
      })),
    })),
    colors: payload!.colors!.map((color) => ({
      hex: color?.hex as string,
      type: color?.type as string,
      brightness: color?.brightness as number,
    })),
    fonts: payload!.fonts!.map((font) => ({
      name: font?.name as string,
      type: font?.type as string,
      origin: font?.origin as string,
      originId: font?.originId as string,
    })),
    images: payload!.images!.map((image) => ({
      type: image!.type as string,
      formats: image!.formats!.map((fmt) => ({
        src: fmt?.src as string,
        background: fmt?.background as string,
        format: fmt?.format as string,
        size: fmt?.size as number,
        height: fmt?.height as number,
        width: fmt?.width as number,
      })),
    })),
  };
};

export const queryBrandInfo = async (
  domainName: string
): Promise<BrandInfo | undefined> => {
  if (EMAIL_DOMAIN_LIST.includes(domainName)) {
    return undefined;
  }

  try {
    const { data } = await apolloClient.query<
      GetBrandInfoQuery,
      GetBrandInfoQueryVariables
    >({
      query: GetBrandInfoDocument,
      variables: {
        domainName,
      },
    });
    return toBrandInfo(data?.getBrandInfo);
  } catch {
    return undefined;
  }
};

export const getLogoSrc = (
  brandInfo: BrandInfo | undefined
): string | undefined => {
  if (brandInfo && brandInfo.logos.length) {
    const logo =
      brandInfo.logos.find((logo) => logo.type === "icon") ||
      brandInfo.logos.find((logo) => logo.type === "symbol") ||
      brandInfo.logos.find((logo) => logo.type === "logo");
    return logo?.formats.find(
      (l) =>
        (l.format === "png" || l.format === "jpeg" || l.format === "jpg") &&
        l.width &&
        l.height &&
        l.width < 600 &&
        l.height < 600
    )?.src;
  } else {
    return undefined;
  }
};

export const getColors = (brandInfo: BrandInfo | undefined) => {
  const defaultBackgroundColor = "#1050C2";

  if (brandInfo && brandInfo.colors.length) {
    const backgroundColor =
      brandInfo.colors.find((color) => color.type === "accent")?.hex ||
      brandInfo.colors.find((color) => color.type === "brand")?.hex ||
      brandInfo.colors.find((color) => color.type === "dark")?.hex ||
      defaultBackgroundColor;
    const lightBackgroundColor = hexAlphaToHex(`${backgroundColor}33`);

    return {
      textColor: getContrastYIQ(backgroundColor),
      backgroundColor,
      lightBackgroundColor,
      textColorForLightBackground: getContrastYIQ(lightBackgroundColor),
    };
  } else {
    return {
      textColor: "#ffffff",
      backgroundColor: defaultBackgroundColor,
      lightBackgroundColor: hexAlphaToHex(`${defaultBackgroundColor}33`),
      textColorForLightBackground: "#000000",
    };
  }
};

const hexAlphaToHex = (hexAlpha: string): string => {
  // Remove '#' if present
  hexAlpha = hexAlpha.replace(/^#/, "");

  // Check if the input is valid (8 characters long)
  if (hexAlpha.length !== 8) {
    console.error("Input should be an 8-digit hexadecimal color code");
    return "#FFFFFF";
  }

  // Extract RGB values and alpha
  const r: number = parseInt(hexAlpha.slice(0, 2), 16);
  const g: number = parseInt(hexAlpha.slice(2, 4), 16);
  const b: number = parseInt(hexAlpha.slice(4, 6), 16);
  const a: number = parseInt(hexAlpha.slice(6, 8), 16);

  // Calculate alpha as a float between 0 and 1
  const alpha: number = a / 255;

  // Blend with white background (255, 255, 255)
  const rNew: number = Math.round(r * alpha + 255 * (1 - alpha));
  const gNew: number = Math.round(g * alpha + 255 * (1 - alpha));
  const bNew: number = Math.round(b * alpha + 255 * (1 - alpha));

  // Convert back to hex
  return `#${rNew.toString(16).padStart(2, "0")}${gNew
    .toString(16)
    .padStart(2, "0")}${bNew.toString(16).padStart(2, "0")}`.toUpperCase();
};
