import { getOrgRegionFromUrl } from "src/helpers/urlHelper";
import { AppInstances } from "./apps";
import { Canvases } from "./canvases";
import { Media } from "./media";

export enum Playlists {
  EMPLOYEE_RECOGNITION = "Employee Recognition",
}

enum PlaylistContentType {
  IMAGES = "images",
  CANVASES = "canvases",
  APPS = "apps",
}

export type PlaylistContent = {
  [PlaylistContentType.IMAGES]: Media[];
  [PlaylistContentType.CANVASES]: Canvases[];
  [PlaylistContentType.APPS]: {
    appName: string;
    appInstancesName: AppInstances[];
  }[];
};

export type PlaylistMetaProps = {
  name: Playlists;
  content: PlaylistContent;
  props: {
    play_mode: string;
    total_items: number;
    total_durations: number;
    default_durations: {
      app: number;
      link: number;
      site: number;
      image: number;
      document: number;
    };
  };
};

type PlaylistsWithMetaType = {
  [key in Playlists]: PlaylistMetaProps;
};

export const playlistWithMeta = (
  playlistName: Playlists
): PlaylistMetaProps => {
  const { isUs } = getOrgRegionFromUrl();
  const playlistMeta: PlaylistsWithMetaType = {
    [Playlists.EMPLOYEE_RECOGNITION]: {
      name: Playlists.EMPLOYEE_RECOGNITION,
      content: {
        images: [],
        canvases: [
          Canvases.CELEBRATION_MESSAGE,
          ...(isUs ? [Canvases.CELEBRATION_MESSAGE_2] : []),
          Canvases.CELEBRATION_MESSAGE_3,
          Canvases.EMPLOYEE_CELEBRATION,
          Canvases.PULSE_SURVEY,
          Canvases.SCAN_CTA,
        ],
        apps: [],
      },
      props: {
        play_mode: "loop",
        total_items: 0,
        total_durations: 0,
        default_durations: {
          app: 20000,
          link: 60000,
          site: 60000,
          image: 10000,
          document: 10000,
        },
      },
    },
  };

  return playlistMeta[playlistName];
};
