import {
  CanvasImages,
  CanvasThumbnailImages,
  FileConfig,
  Media,
} from "../config/media";
import { AppInstanceConfig } from "../config/apps";
import { getColors, getLogoSrc } from "../entities/brand";

export interface CanvasConfig extends AppInstanceConfig {
  config: {
    artboards: {
      objects: {
        src?: string;
        _ref?: {
          id: string;
          type?: string;
        };
        [key: string]: unknown; // Index signature for additional properties
      }[];
      backgroundImage?: {
        src: string;
        _ref: {
          id: string;
          type: string;
        };
        [key: string]: unknown; // Index signature for additional properties
      };
      [key: string]: unknown; // Index signature for additional properties
    }[];
    [key: string]: unknown; // Index signature for additional properties
  };
  images: FileConfig[];
  thumbnail: FileConfig;
}

export interface CanvasesConfig {
  [key: string]: CanvasConfig;
}

export enum Canvases {
  LOGO_HEADER = "Logo Header",
  CELEBRATION_MESSAGE = "Celebration Message",
  CELEBRATION_MESSAGE_2 = "Celebration Message 2",
  CELEBRATION_MESSAGE_3 = "Celebration Message 3",
  EMPLOYEE_CELEBRATION = "Employee Celebration",
  PULSE_SURVEY = "Pulse Survey",
  SCAN_CTA = "Scan CTA",
}

export const canvasesWithMeta = (brandInfo, orgName): CanvasesConfig => {
  const {
    backgroundColor,
    textColor,
    lightBackgroundColor,
    textColorForLightBackground,
  } = getColors(brandInfo);
  const logoSrc = getLogoSrc(brandInfo);
  const canvasesMeta: CanvasesConfig = {
    [Canvases.LOGO_HEADER]: {
      version: "2.0.8",
      config: {
        qrCodes: [],
        artboards: [
          {
            objects: logoSrc
              ? [
                  {
                    top: -461.77,
                    fill: backgroundColor,
                    left: -974.51,
                    type: "circle",
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    skewX: 0,
                    skewY: 0,
                    width: 100,
                    clipTo: null,
                    height: 100,
                    radius: 50,
                    scaleX: 19.14,
                    scaleY: 7.88,
                    shadow: null,
                    stroke: null,
                    opacity: 1,
                    originX: "left",
                    originY: "top",
                    version: "3.4.0",
                    visible: true,
                    endAngle: 6.283185307179586,
                    fillRule: "nonzero",
                    hasBorders: true,
                    paintFirst: "fill",
                    startAngle: 0,
                    hasControls: true,
                    strokeWidth: 0,
                    lockRotation: false,
                    lockMovementX: false,
                    lockMovementY: false,
                    strokeLineCap: "butt",
                    lockUniScaling: false,
                    strokeLineJoin: "miter",
                    backgroundColor: "",
                    strokeDashArray: null,
                    transformMatrix: null,
                    strokeDashOffset: 0,
                    strokeMiterLimit: 4,
                    _controlsVisibility: {
                      bl: true,
                      br: true,
                      mb: true,
                      ml: true,
                      mr: true,
                      mt: true,
                      tl: true,
                      tr: true,
                      mtr: true,
                    },
                    globalCompositeOperation: "source-over",
                  },
                  {
                    top: 28.86,
                    fill: textColor,
                    left: 137.76,
                    text: orgName,
                    type: "textbox",
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    skewX: 0,
                    skewY: 0,
                    width: 730.09,
                    clipTo: null,
                    height: 54.24,
                    scaleX: 1,
                    scaleY: 1,
                    shadow: null,
                    stroke: null,
                    styles: {},
                    opacity: 1,
                    originX: "left",
                    originY: "top",
                    version: "3.4.0",
                    visible: true,
                    editable: true,
                    fillRule: "nonzero",
                    fontSize: 48,
                    minWidth: 20,
                    overline: false,
                    fontStyle: "normal",
                    textAlign: "left",
                    underline: false,
                    fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                    fontWeight: "bold",
                    hasBorders: true,
                    lineHeight: 1.0619469026548674,
                    paintFirst: "fill",
                    charSpacing: 0,
                    hasControls: true,
                    linethrough: false,
                    strokeWidth: 0,
                    lockRotation: false,
                    lockMovementX: false,
                    lockMovementY: false,
                    strokeLineCap: "butt",
                    lockUniScaling: false,
                    strokeLineJoin: "miter",
                    backgroundColor: "",
                    splitByGrapheme: false,
                    strokeDashArray: null,
                    transformMatrix: null,
                    strokeDashOffset: 0,
                    strokeMiterLimit: 4,
                    _controlsVisibility: {
                      bl: false,
                      br: false,
                      mb: false,
                      ml: true,
                      mr: true,
                      mt: false,
                      tl: false,
                      tr: false,
                      mtr: true,
                    },
                    textBackgroundColor: "",
                    globalCompositeOperation: "source-over",
                  },
                  {
                    src: logoSrc,
                    top: 18.18,
                    fill: "rgb(0,0,0)",
                    left: 34.36,
                    type: "image",
                    angle: 0,
                    cropX: 0,
                    cropY: 0,
                    flipX: false,
                    flipY: false,
                    skewX: 0,
                    skewY: 0,
                    width: 500,
                    clipTo: null,
                    height: 500,
                    scaleX: 0.15,
                    scaleY: 0.15,
                    shadow: null,
                    stroke: null,
                    filters: [],
                    opacity: 1,
                    originX: "left",
                    originY: "top",
                    version: "3.4.0",
                    visible: true,
                    fillRule: "nonzero",
                    hasBorders: true,
                    paintFirst: "fill",
                    crossOrigin: "Anonymous",
                    hasControls: true,
                    strokeWidth: 0,
                    lockRotation: false,
                    lockMovementX: false,
                    lockMovementY: false,
                    strokeLineCap: "butt",
                    lockUniScaling: false,
                    strokeLineJoin: "miter",
                    backgroundColor: "",
                    strokeDashArray: null,
                    transformMatrix: null,
                    strokeDashOffset: 0,
                    strokeMiterLimit: 4,
                    globalCompositeOperation: "source-over",
                  },
                ]
              : [
                  {
                    top: -501.19,
                    fill: backgroundColor,
                    left: -1038.7,
                    type: "circle",
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    skewX: 0,
                    skewY: 0,
                    width: 100,
                    clipTo: null,
                    height: 100,
                    radius: 50,
                    scaleX: 19.14,
                    scaleY: 7.88,
                    shadow: null,
                    stroke: null,
                    opacity: 1,
                    originX: "left",
                    originY: "top",
                    version: "3.4.0",
                    visible: true,
                    endAngle: 6.283185307179586,
                    fillRule: "nonzero",
                    hasBorders: true,
                    paintFirst: "fill",
                    startAngle: 0,
                    hasControls: true,
                    strokeWidth: 0,
                    lockRotation: false,
                    lockMovementX: false,
                    lockMovementY: false,
                    strokeLineCap: "butt",
                    lockUniScaling: false,
                    strokeLineJoin: "miter",
                    backgroundColor: "",
                    strokeDashArray: null,
                    transformMatrix: null,
                    strokeDashOffset: 0,
                    strokeMiterLimit: 4,
                    _controlsVisibility: {
                      bl: true,
                      br: true,
                      mb: true,
                      ml: true,
                      mr: true,
                      mt: true,
                      tl: true,
                      tr: true,
                      mtr: true,
                    },
                    globalCompositeOperation: "source-over",
                  },
                  {
                    top: 28.56,
                    fill: textColor,
                    left: 33.5,
                    text: orgName,
                    type: "textbox",
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    skewX: 0,
                    skewY: 0,
                    width: 713.67,
                    clipTo: null,
                    height: 54.24,
                    scaleX: 1,
                    scaleY: 1,
                    shadow: null,
                    stroke: null,
                    styles: {},
                    opacity: 1,
                    originX: "left",
                    originY: "top",
                    version: "3.4.0",
                    visible: true,
                    editable: true,
                    fillRule: "nonzero",
                    fontSize: 48,
                    minWidth: 20,
                    overline: false,
                    fontStyle: "normal",
                    textAlign: "left",
                    underline: false,
                    fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                    fontWeight: "bold",
                    hasBorders: true,
                    lineHeight: 1.0619469026548674,
                    paintFirst: "fill",
                    charSpacing: 0,
                    hasControls: true,
                    linethrough: false,
                    strokeWidth: 0,
                    lockRotation: false,
                    lockMovementX: false,
                    lockMovementY: false,
                    strokeLineCap: "butt",
                    lockUniScaling: false,
                    strokeLineJoin: "miter",
                    backgroundColor: "",
                    splitByGrapheme: false,
                    strokeDashArray: null,
                    transformMatrix: null,
                    strokeDashOffset: 0,
                    strokeMiterLimit: 4,
                    _controlsVisibility: {
                      bl: false,
                      br: false,
                      mb: false,
                      ml: true,
                      mr: true,
                      mt: false,
                      tl: false,
                      tr: false,
                      mtr: true,
                    },
                    textBackgroundColor: "",
                    globalCompositeOperation: "source-over",
                  },
                ],
            version: "3.4.0",
            background: "#fafafa",
          },
        ],
        canvasWidth: 1492,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIvb3JpZ2luYWxzL2ExNjQwNmEyLTJlOGQtNDExMC1hM2Y0LWVjNzUwMTI5ZDI0N19Tb3JhLUJvbGQudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMDAxODk1NjMtNWZjNi00ODhmLWJjYWItMGVkOGIzYmIzODhiIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzAwMTg5NTYzLTVmYzYtNDg4Zi1iY2FiLTBlZDhiM2JiMzg4Yi9vcmlnaW5hbHMvMzM4ZTcyYTMtNDcxMy00NDljLWE3MzctMTMwYjAxYzE1ZDM1X1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQ==",
          },
        ],
        canvasHeight: 110,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_BANNER],
      images: [],
    },
    [Canvases.CELEBRATION_MESSAGE]: {
      version: "2.0.8",
      config: {
        qrCodes: [],
        artboards: [
          {
            objects: [
              {
                top: 676.8,
                fill: "#000000",
                left: -181.82,
                type: "circle",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 100,
                clipTo: null,
                height: 100,
                radius: 50,
                scaleX: 5.9,
                scaleY: 5.9,
                shadow: null,
                stroke: null,
                opacity: 0.1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                endAngle: 6.283185307179586,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                startAngle: 0,
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                src: CanvasImages[Media.CANVAS_EMPLOYEE].source,
                top: -127.76,
                _ref: {
                  id: "a01907b7-6689-40cb-99f8-bd7ec85a1b6e",
                  type: "file",
                },
                fill: "rgb(0,0,0)",
                left: 43.89,
                type: "image",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 3840,
                clipTo: null,
                height: 5760,
                scaleX: 0.23,
                scaleY: 0.23,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                clipPath: {
                  top: -1920,
                  fill: "rgb(0,0,0)",
                  left: -1920,
                  type: "circle",
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  skewX: 0,
                  skewY: 0,
                  width: 3840,
                  clipTo: null,
                  height: 3840,
                  radius: 1920,
                  scaleX: 1,
                  scaleY: 1,
                  shadow: null,
                  stroke: null,
                  opacity: 1,
                  originX: "left",
                  originY: "top",
                  version: "3.4.0",
                  visible: true,
                  endAngle: 6.283185307179586,
                  fillRule: "nonzero",
                  inverted: false,
                  paintFirst: "fill",
                  startAngle: 0,
                  strokeWidth: 1,
                  strokeLineCap: "butt",
                  strokeLineJoin: "miter",
                  backgroundColor: "",
                  strokeDashArray: null,
                  transformMatrix: null,
                  strokeDashOffset: 0,
                  strokeMiterLimit: 4,
                  absolutePositioned: false,
                  globalCompositeOperation: "source-over",
                },
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "Anonymous",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
              {
                top: 263.66,
                fill: textColorForLightBackground,
                left: 1011.91,
                text: "HAPPY ANNIVERSARY",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 934.32,
                clipTo: null,
                height: 323,
                scaleX: 0.9,
                scaleY: 0.9,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {
                      fontSize: 210,
                      lineHeight: 2.4485192702139343e-12,
                    },
                    "1": {
                      fontSize: 210,
                      lineHeight: 2.4485192702139343e-12,
                    },
                    "2": {
                      fontSize: 210,
                      lineHeight: 2.4485192702139343e-12,
                    },
                    "3": {
                      fontSize: 210,
                      lineHeight: 2.4485192702139343e-12,
                    },
                    "4": {
                      fontSize: 210,
                      lineHeight: 2.4485192702139343e-12,
                    },
                    "5": {},
                    "6": {},
                    "7": {},
                    "8": {},
                    "9": {},
                    "10": {},
                    "11": {},
                    "12": {},
                    "13": {},
                    "14": {},
                    "15": {},
                    "16": {},
                    "24": {
                      fontFamily:
                        "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 100,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.8849557522123894,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 724.7,
                fill: textColorForLightBackground,
                left: 1014.06,
                text:
                  "Keep paragraphs short, simple and to the point. Consider how much time your viewers will take to read this while passing by.",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 847.72,
                clipTo: null,
                height: 141.2,
                scaleX: 0.9,
                scaleY: 0.9,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 40,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 591.35,
                fill: textColorForLightBackground,
                left: 1014.77,
                text: "FirstName LastName",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 815.29,
                clipTo: null,
                height: 72.32,
                scaleX: 0.9,
                scaleY: 0.9,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {},
                    "1": {},
                    "2": {},
                    "3": {},
                    "4": {},
                    "5": {},
                    "6": {},
                    "7": {},
                    "8": {},
                    "9": {},
                    "10": {},
                    "11": {},
                    "12": {},
                    "13": {},
                    "14": {},
                    "15": {},
                    "16": {},
                    "17": {},
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 64,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.6637168141592921,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 140.85,
                fill: "#000000",
                left: 978.61,
                text: "🥳",
                type: "textbox",
                angle: 0.62,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 176.38,
                clipTo: null,
                height: 113,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 100,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "center",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: lightBackgroundColor,
          },
        ],
        canvasWidth: 1920,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIvb3JpZ2luYWxzL2ExNjQwNmEyLTJlOGQtNDExMC1hM2Y0LWVjNzUwMTI5ZDI0N19Tb3JhLUJvbGQudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMDAxODk1NjMtNWZjNi00ODhmLWJjYWItMGVkOGIzYmIzODhiIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzAwMTg5NTYzLTVmYzYtNDg4Zi1iY2FiLTBlZDhiM2JiMzg4Yi9vcmlnaW5hbHMvMzM4ZTcyYTMtNDcxMy00NDljLWE3MzctMTMwYjAxYzE1ZDM1X1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQ==",
          },
        ],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_DEFAULT],
      images: [CanvasImages[Media.CANVAS_EMPLOYEE]],
    },
    [Canvases.CELEBRATION_MESSAGE_2]: {
      version: "2.0.8",
      config: {
        qrCodes: [],
        artboards: [
          {
            objects: [
              {
                src: CanvasImages[Media.CANVAS_BLUE_SKY_CELEBRATE_BG].source,
                top: -389.0766004609211,
                _ref: {
                  id: "fefac325-3461-4f10-a9f5-e3db9fd7d924",
                  type: "file",
                },
                fill: "rgb(0,0,0)",
                left: -3.60902255639121,
                type: "image",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 4019,
                clipTo: null,
                height: 3166,
                scaleX: 0.479971965954883,
                scaleY: 0.479971965954883,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                clipPath: {
                  rx: 0,
                  ry: 0,
                  top: -1583,
                  fill: "rgb(0,0,0)",
                  left: -2009.5,
                  type: "rect",
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  skewX: 0,
                  skewY: 0,
                  width: 4019,
                  clipTo: null,
                  height: 3166,
                  scaleX: 1,
                  scaleY: 1,
                  shadow: null,
                  stroke: null,
                  opacity: 1,
                  originX: "left",
                  originY: "top",
                  version: "3.4.0",
                  visible: true,
                  fillRule: "nonzero",
                  inverted: false,
                  paintFirst: "fill",
                  strokeWidth: 1,
                  strokeLineCap: "butt",
                  strokeLineJoin: "miter",
                  backgroundColor: "",
                  strokeDashArray: null,
                  transformMatrix: null,
                  strokeDashOffset: 0,
                  strokeMiterLimit: 4,
                  absolutePositioned: false,
                  globalCompositeOperation: "source-over",
                },
                fillRule: "nonzero",
                hasBorders: false,
                paintFirst: "fill",
                crossOrigin: "Anonymous",
                hasControls: false,
                strokeWidth: 0,
                lockRotation: true,
                lockMovementX: true,
                lockMovementY: true,
                strokeLineCap: "butt",
                lockUniScaling: true,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
              {
                top: 252.96395500028268,
                fill: "#26487f",
                left: 165.5106390977444,
                text: "HAPPY \n4TH OF JULY EVERYONE!",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 1147.141729323308,
                clipTo: null,
                height: 530.8,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {
                      fontSize: 240,
                      fontFamily: "Abril Fatface",
                      lineHeight: 0.002941944765946502,
                    },
                    "1": {
                      fontSize: 240,
                      fontFamily: "Abril Fatface",
                      lineHeight: 0.002941944765946502,
                    },
                    "2": {
                      fontSize: 240,
                      fontFamily: "Abril Fatface",
                      lineHeight: 0.002941944765946502,
                    },
                    "3": {
                      fontSize: 240,
                      fontFamily: "Abril Fatface",
                      lineHeight: 0.002941944765946502,
                    },
                    "4": {
                      fontSize: 240,
                      fontFamily: "Abril Fatface",
                      lineHeight: 0.002941944765946502,
                    },
                    "5": {
                      fontFamily: "Abril Fatface",
                    },
                    "6": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                  },
                  "1": {
                    "0": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "1": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "2": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "3": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "4": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "5": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "6": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "7": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "8": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "9": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "10": {
                      fill: "#ca2323",
                      fontFamily: "Abril Fatface",
                    },
                    "11": {
                      fontFamily: "Abril Fatface",
                    },
                    "12": {
                      fontFamily: "Abril Fatface",
                    },
                    "13": {
                      fontFamily: "Abril Fatface",
                    },
                    "14": {
                      fontFamily: "Abril Fatface",
                    },
                    "15": {
                      fontFamily: "Abril Fatface",
                    },
                    "16": {
                      fontFamily: "Abril Fatface",
                    },
                    "17": {
                      fontFamily: "Abril Fatface",
                    },
                    "18": {
                      fontFamily: "Abril Fatface",
                    },
                    "19": {
                      fontFamily: "Abril Fatface",
                    },
                    "20": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Playfair Display",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.7743362831858407,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 811.5150562496469,
                fill: "#26487f",
                left: 230.97744360902243,
                text:
                  "Keep paragraphs short, simple and to the point. Consider how much time your viewers will take to read this while passing by.",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 842.7067669172934,
                clipTo: null,
                height: 141.2,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 40,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                rx: 0,
                ry: 0,
                top: 804.7840185284642,
                fill: "#26487f",
                left: 170.79699248120352,
                type: "rect",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 80,
                clipTo: null,
                height: 50,
                scaleX: 0.278571428571422,
                scaleY: 2.962072568639646,
                shadow: null,
                stroke: null,
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: "#1050c2",
          },
        ],
        canvasWidth: 1920,
        customFonts: [],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_DEFAULT],
      images: [CanvasImages[Media.CANVAS_BLUE_SKY_CELEBRATE_BG]],
    },
    [Canvases.CELEBRATION_MESSAGE_3]: {
      version: "2.0.8",
      config: {
        qrCodes: [],
        artboards: [
          {
            objects: [
              {
                top: -122.15,
                fill: "#000000",
                left: -691.25,
                type: "circle",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 100,
                clipTo: null,
                height: 100,
                radius: 50,
                scaleX: 14.83,
                scaleY: 14.21,
                shadow: null,
                stroke: null,
                opacity: 0.1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                endAngle: 6.283185307179586,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                startAngle: 0,
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                top: 133.76,
                fill: textColorForLightBackground,
                left: 960.53,
                text: "Happy  \nBirthday",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 848.16,
                clipTo: null,
                height: 344.84,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "1": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "2": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "3": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "4": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "5": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "6": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "7": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                  },
                  "1": {
                    "0": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "1": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "2": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "3": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "4": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "5": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "6": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                    "7": {
                      fontSize: 148,
                      lineHeight: 2.93524638223526e-20,
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 648.28,
                fill: textColorForLightBackground,
                left: 973.35,
                text:
                  "Keep paragraphs short, simple and to the point. Consider how much time your viewers will take to read this while passing by.",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 692.24,
                clipTo: null,
                height: 227.04,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 48,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 504.9,
                fill: textColorForLightBackground,
                left: 970.35,
                text: "FirstName LastName",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 815.29,
                clipTo: null,
                height: 81.36,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {},
                    "1": {},
                    "2": {},
                    "3": {},
                    "4": {},
                    "5": {},
                    "6": {},
                    "7": {},
                    "8": {},
                    "9": {},
                    "10": {},
                    "11": {},
                    "12": {},
                    "13": {},
                    "14": {},
                    "15": {},
                    "16": {},
                    "17": {},
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 72,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.5899705014749262,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 829.56,
                fill: "#000000",
                left: 127.6,
                text: "🍩",
                type: "textbox",
                angle: -25.52,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 176.38,
                clipTo: null,
                height: 135.6,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 120,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 215.79,
                fill: "#000000",
                left: 607.91,
                text: "🎉",
                type: "textbox",
                angle: -0.39,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 280,
                clipTo: null,
                height: 226,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 200,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 85.15,
                fill: "#000000",
                left: 1574.44,
                text: "🎈",
                type: "textbox",
                angle: 349.8,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 300,
                clipTo: null,
                height: 339,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 300,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 701.81,
                fill: "#000000",
                left: 460.22,
                text: "🎁",
                type: "textbox",
                angle: -28.2,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 262.73,
                clipTo: null,
                height: 226,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 200,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 369.23,
                fill: "#000000",
                left: 691.9,
                text: "🍰",
                type: "textbox",
                angle: 34.42,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 200,
                clipTo: null,
                height: 226,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 200,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 572.08,
                fill: "#000000",
                left: 321.45,
                text: "🎊",
                type: "textbox",
                angle: 46.82,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 280,
                clipTo: null,
                height: 271.2,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 240,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 520.28,
                fill: "#000000",
                left: 141.4,
                text: "🥂",
                type: "textbox",
                angle: -61.09,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 176.38,
                clipTo: null,
                height: 180.8,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontSize: 148,
                      fontFamily: "Abril Fatface",
                      lineHeight: 2.93524638223526e-20,
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 158.65,
                fill: "#000000",
                left: 560.12,
                text: "🍾",
                type: "textbox",
                angle: 59.39,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 240,
                clipTo: null,
                height: 271.2,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 240,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 334.8,
                fill: "#000000",
                left: 177.69,
                text: "🧁",
                type: "textbox",
                angle: -38.02,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 300,
                clipTo: null,
                height: 271.2,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 240,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 804.15,
                fill: "#000000",
                left: 340.49,
                text: "🪅",
                type: "textbox",
                angle: -25.52,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 176.38,
                clipTo: null,
                height: 180.8,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontSize: 148,
                      fontFamily: "Abril Fatface",
                      lineHeight: 2.93524638223526e-20,
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 195.5,
                fill: "#000000",
                left: 125.81,
                text: "💙",
                type: "textbox",
                angle: -39.39,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 161.06,
                clipTo: null,
                height: 180.8,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {},
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 705.92,
                fill: "#000000",
                left: 144.4,
                text: "💙",
                type: "textbox",
                angle: -113.3,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 161.06,
                clipTo: null,
                height: 180.8,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {},
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 819.12,
                fill: "#000000",
                left: 817.55,
                text: "💙",
                type: "textbox",
                angle: 136.78,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 161.06,
                clipTo: null,
                height: 180.8,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {},
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 160,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 179.82,
                fill: "#000000",
                left: 394.4,
                text: "🎂",
                type: "textbox",
                angle: 9.04,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 176.38,
                clipTo: null,
                height: 135.6,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 120,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 722.83,
                fill: "#000000",
                left: 795.99,
                text: "🍭",
                type: "textbox",
                angle: 171.96,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 280,
                clipTo: null,
                height: 226,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 200,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 441.84,
                fill: "#000000",
                left: 376.1,
                text: "🥳",
                type: "textbox",
                angle: -5.59,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 240,
                clipTo: null,
                height: 271.2,
                scaleX: 0.82,
                scaleY: 0.82,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "8": {
                      fontFamily: "Abril Fatface",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 240,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: lightBackgroundColor,
          },
        ],
        canvasWidth: 1920,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIvb3JpZ2luYWxzL2ExNjQwNmEyLTJlOGQtNDExMC1hM2Y0LWVjNzUwMTI5ZDI0N19Tb3JhLUJvbGQudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMDAxODk1NjMtNWZjNi00ODhmLWJjYWItMGVkOGIzYmIzODhiIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzAwMTg5NTYzLTVmYzYtNDg4Zi1iY2FiLTBlZDhiM2JiMzg4Yi9vcmlnaW5hbHMvMzM4ZTcyYTMtNDcxMy00NDljLWE3MzctMTMwYjAxYzE1ZDM1X1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQ==",
          },
          {
            label: "Sora",
            value: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEvb3JpZ2luYWxzL2M2MjRjMjY1LTNkZDAtNDE4Yi1iMGM1LWJlM2RkNDIxMjM5M19Tb3JhLVJlZ3VsYXIudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvZWRhODY3NWQtODkwOS00YzVkLTg4MzctODViN2VhYzVkOGZjX1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQpAZm9udC1mYWNlIHsKICAgICAgZm9udC1mYW1pbHk6ICJzY19Tb3JhXzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MSI7CiAgICAgIGZvbnQtc3R5bGU6IGl0YWxpYzsKICAgICAgZm9udC13ZWlnaHQ6IG5vcm1hbDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvMmRlMTU1ODQtZDVmMC00NjRjLTk2ZDEtYzkyYzM3ZmNjZTFhX1NvcmEtQm9sZC50dGYiKTsKICAgIH0KQGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBpdGFsaWM7CiAgICAgIGZvbnQtd2VpZ2h0OiBib2xkOwogICAgICBzcmM6IHVybCgiaHR0cHM6Ly9tZWRpYS51cy5zY3JlZW5jbG91ZC5jb20vMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxL29yaWdpbmFscy82ZjMyYzA5MS0zN2I5LTQzYWMtYjJjOC1jNTU0NDc1NzE5ZTNfU29yYS1MaWdodC50dGYiKTsKICAgIH0=",
          },
        ],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_DEFAULT],
      images: [],
    },
    [Canvases.EMPLOYEE_CELEBRATION]: {
      version: "2.0.8",
      config: {
        qrCodes: [],
        artboards: [
          {
            objects: [
              {
                src: CanvasImages[Media.CANVAS_BLACK_CHEVRONS_BG].source,
                top: -575.69,
                _ref: {
                  id: "f8a5f644-469b-4052-9546-bd0ed9192ec2",
                  type: "file",
                },
                fill: "rgb(0,0,0)",
                left: 3.25,
                type: "image",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 4570,
                clipTo: null,
                height: 2570,
                scaleX: 0.75,
                scaleY: 0.75,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 0.1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "Anonymous",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
              {
                src: CanvasImages[Media.CANVAS_EMPLOYEE_CELEBRATION].source,
                top: 77.63,
                _ref: {
                  id: "9e3497e8-84b2-4e34-9ed0-e9ca56eff1c8",
                  type: "file",
                },
                fill: "rgb(0,0,0)",
                left: -172.36,
                type: "image",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 5578,
                clipTo: null,
                height: 3719,
                scaleX: 0.25,
                scaleY: 0.25,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                clipPath: {
                  top: -1859.5,
                  fill: "rgb(0,0,0)",
                  left: -1859.5,
                  type: "circle",
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  skewX: 0,
                  skewY: 0,
                  width: 3719,
                  clipTo: null,
                  height: 3719,
                  radius: 1859.5,
                  scaleX: 1,
                  scaleY: 1,
                  shadow: null,
                  stroke: null,
                  opacity: 1,
                  originX: "left",
                  originY: "top",
                  version: "3.4.0",
                  visible: true,
                  endAngle: 6.283185307179586,
                  fillRule: "nonzero",
                  inverted: false,
                  paintFirst: "fill",
                  startAngle: 0,
                  strokeWidth: 1,
                  strokeLineCap: "butt",
                  strokeLineJoin: "miter",
                  backgroundColor: "",
                  strokeDashArray: null,
                  transformMatrix: null,
                  strokeDashOffset: 0,
                  strokeMiterLimit: 4,
                  absolutePositioned: false,
                  globalCompositeOperation: "source-over",
                },
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "Anonymous",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
              {
                rx: 0,
                ry: 0,
                top: 215.6,
                fill: "#ffffff",
                left: 762.66,
                type: "rect",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 80,
                clipTo: null,
                height: 50,
                scaleX: 13.54,
                scaleY: 13.86,
                shadow: null,
                stroke: null,
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                top: 320.17,
                fill: "#000000",
                left: 864.61,
                text: "Well done to Recipient's Name",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 769.68,
                clipTo: null,
                height: 167.76,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 72,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 521.92,
                fill: "#000000",
                left: 862.83,
                text:
                  "You have always embodied outstanding behaviour in the way you work. On behalf of the company, thank you for leading by example in the way you work each day!",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 842.72,
                clipTo: null,
                height: 284.64,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 48,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 92.03,
                fill: "#000000",
                left: 1470.54,
                text: "🌟",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 349.37,
                clipTo: null,
                height: 282.5,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 250,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "right",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: lightBackgroundColor,
          },
        ],
        canvasWidth: 1920,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_00189563-5fc6-488f-bcab-0ed8b3bb388b",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8wMDE4OTU2My01ZmM2LTQ4OGYtYmNhYi0wZWQ4YjNiYjM4OGIvb3JpZ2luYWxzL2ExNjQwNmEyLTJlOGQtNDExMC1hM2Y0LWVjNzUwMTI5ZDI0N19Tb3JhLUJvbGQudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMDAxODk1NjMtNWZjNi00ODhmLWJjYWItMGVkOGIzYmIzODhiIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzAwMTg5NTYzLTVmYzYtNDg4Zi1iY2FiLTBlZDhiM2JiMzg4Yi9vcmlnaW5hbHMvMzM4ZTcyYTMtNDcxMy00NDljLWE3MzctMTMwYjAxYzE1ZDM1X1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQ==",
          },
        ],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_DEFAULT],
      images: [
        CanvasImages[Media.CANVAS_BLACK_CHEVRONS_BG],
        CanvasImages[Media.CANVAS_EMPLOYEE_CELEBRATION],
      ],
    },
    [Canvases.PULSE_SURVEY]: {
      version: "2.0.8",
      config: {
        qrCodes: [
          {
            id: "75dc5f40-2760-4acb-a733-7f7193e4e578",
            url: "https://scan.sc/2MHyK4sVYAyGWs2KScmmYS",
            bgColor: "transparent",
            fgColor: "#FFFFFF",
          },
        ],
        artboards: [
          {
            objects: [
              {
                top: 67.53,
                fill: lightBackgroundColor,
                left: 47.54,
                type: "circle",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 100,
                clipTo: null,
                height: 100,
                radius: 50,
                scaleX: 5.72,
                scaleY: 5.72,
                shadow: null,
                stroke: null,
                opacity: 0.66,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                endAngle: 6.283185307179586,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                startAngle: 0,
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                top: 230.61,
                fill: "#ffffff",
                left: 179.95,
                text: "How are you feeling?",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 786.39,
                clipTo: null,
                height: 245.6,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 120,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.8112094395280236,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 526.99,
                fill: "#ffffff",
                left: 182.56,
                text:
                  "Please take 3 minutes to complete this short survey. \n\nThis is your chance to provide valuable & anonymous feedback for your the company. ",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 681.8,
                clipTo: null,
                height: 349.72,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "0": {
                    "0": {
                      fontWeight: "bold",
                    },
                    "1": {
                      fontWeight: "bold",
                    },
                    "2": {
                      fontWeight: "bold",
                    },
                    "3": {
                      fontWeight: "bold",
                    },
                    "4": {
                      fontWeight: "bold",
                    },
                    "5": {
                      fontWeight: "bold",
                    },
                    "6": {
                      fontWeight: "bold",
                    },
                    "7": {
                      fontWeight: "bold",
                    },
                    "8": {
                      fontWeight: "bold",
                    },
                    "9": {
                      fontWeight: "bold",
                    },
                    "10": {
                      fontWeight: "bold",
                    },
                    "11": {
                      fontWeight: "bold",
                    },
                    "12": {
                      fontWeight: "bold",
                    },
                    "13": {
                      fontWeight: "bold",
                    },
                    "14": {
                      fontWeight: "bold",
                    },
                    "15": {
                      fontWeight: "bold",
                    },
                    "16": {
                      fontWeight: "bold",
                    },
                    "17": {
                      fontWeight: "bold",
                    },
                    "18": {
                      fontWeight: "bold",
                    },
                    "19": {
                      fontWeight: "bold",
                    },
                    "20": {
                      fontWeight: "bold",
                    },
                    "21": {
                      fontWeight: "bold",
                    },
                    "22": {
                      fontWeight: "bold",
                    },
                    "23": {
                      fontWeight: "bold",
                    },
                    "24": {
                      fontWeight: "bold",
                    },
                    "25": {
                      fontWeight: "bold",
                    },
                    "26": {
                      fontWeight: "bold",
                    },
                    "27": {
                      fontWeight: "bold",
                    },
                    "28": {
                      fontWeight: "bold",
                    },
                    "29": {
                      fontWeight: "bold",
                    },
                    "30": {
                      fontWeight: "bold",
                    },
                    "31": {
                      fontWeight: "bold",
                    },
                    "32": {
                      fontWeight: "bold",
                    },
                    "33": {
                      fontWeight: "bold",
                    },
                    "34": {
                      fontWeight: "bold",
                    },
                    "35": {
                      fontWeight: "bold",
                    },
                    "36": {
                      fontWeight: "bold",
                    },
                    "37": {
                      fontWeight: "bold",
                    },
                    "38": {
                      fontWeight: "bold",
                    },
                    "39": {
                      fontWeight: "bold",
                    },
                    "40": {
                      fontWeight: "bold",
                    },
                    "41": {
                      fontWeight: "bold",
                    },
                    "42": {
                      fontWeight: "bold",
                    },
                    "43": {
                      fontWeight: "bold",
                    },
                    "44": {
                      fontWeight: "bold",
                    },
                    "45": {
                      fontWeight: "bold",
                    },
                    "46": {
                      fontWeight: "bold",
                    },
                    "47": {
                      fontWeight: "bold",
                    },
                    "48": {
                      fontWeight: "bold",
                    },
                    "49": {
                      fontWeight: "bold",
                    },
                    "50": {
                      fontWeight: "bold",
                    },
                    "51": {
                      fontWeight: "bold",
                    },
                    "52": {
                      fontWeight: "bold",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 44,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 1.2067578439259856,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                src: "data:image/png;base64",
                top: 182.98,
                _ref: {
                  id: "75dc5f40-2760-4acb-a733-7f7193e4e578",
                },
                fill: "rgb(0,0,0)",
                left: 1153.68,
                type: "qrcode",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 800,
                clipTo: null,
                height: 800,
                scaleX: 0.9,
                scaleY: 0.9,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: lightBackgroundColor,
            backgroundImage: {
              src: CanvasImages[Media.CANVAS_GREEN_FOREST_BG].source,
              top: -164.1072925398156,
              _ref: {
                id: "44713cb6-5aa1-4961-a758-cbbddb647b15",
                type: "file",
              },
              fill: "rgb(0,0,0)",
              left: 0,
              type: "image",
              angle: 0,
              cropX: 0,
              cropY: 0,
              flipX: false,
              flipY: false,
              skewX: 0,
              skewY: 0,
              width: 4772,
              clipTo: null,
              height: 3500,
              scaleX: 0.402347024308466,
              scaleY: 0.402347024308466,
              shadow: null,
              stroke: null,
              filters: [],
              opacity: 1,
              originX: "left",
              originY: "top",
              version: "3.4.0",
              visible: true,
              clipPath: {
                rx: 0,
                ry: 0,
                top: -1750,
                fill: "rgb(0,0,0)",
                left: -2386,
                type: "rect",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 4772,
                clipTo: null,
                height: 3500,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                inverted: false,
                paintFirst: "fill",
                strokeWidth: 1,
                strokeLineCap: "butt",
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                absolutePositioned: false,
                globalCompositeOperation: "source-over",
              },
              fillRule: "nonzero",
              hasBorders: true,
              paintFirst: "fill",
              crossOrigin: "Anonymous",
              hasControls: true,
              strokeWidth: 0,
              lockRotation: false,
              lockMovementX: false,
              lockMovementY: false,
              strokeLineCap: "butt",
              lockUniScaling: false,
              strokeLineJoin: "miter",
              backgroundColor: "",
              strokeDashArray: null,
              transformMatrix: null,
              strokeDashOffset: 0,
              strokeMiterLimit: 4,
              globalCompositeOperation: "source-over",
            },
          },
        ],
        canvasWidth: 1920,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEvb3JpZ2luYWxzL2M2MjRjMjY1LTNkZDAtNDE4Yi1iMGM1LWJlM2RkNDIxMjM5M19Tb3JhLVJlZ3VsYXIudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvZWRhODY3NWQtODkwOS00YzVkLTg4MzctODViN2VhYzVkOGZjX1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQpAZm9udC1mYWNlIHsKICAgICAgZm9udC1mYW1pbHk6ICJzY19Tb3JhXzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MSI7CiAgICAgIGZvbnQtc3R5bGU6IGl0YWxpYzsKICAgICAgZm9udC13ZWlnaHQ6IG5vcm1hbDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvMmRlMTU1ODQtZDVmMC00NjRjLTk2ZDEtYzkyYzM3ZmNjZTFhX1NvcmEtQm9sZC50dGYiKTsKICAgIH0KQGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBpdGFsaWM7CiAgICAgIGZvbnQtd2VpZ2h0OiBib2xkOwogICAgICBzcmM6IHVybCgiaHR0cHM6Ly9tZWRpYS51cy5zY3JlZW5jbG91ZC5jb20vMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxL29yaWdpbmFscy82ZjMyYzA5MS0zN2I5LTQzYWMtYjJjOC1jNTU0NDc1NzE5ZTNfU29yYS1MaWdodC50dGYiKTsKICAgIH0=",
          },
        ],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_QRCODE],
      images: [CanvasImages[Media.CANVAS_GREEN_FOREST_BG]],
    },
    [Canvases.SCAN_CTA]: {
      version: "2.0.8",
      config: {
        qrCodes: [
          {
            id: "85e20501-66c7-4a2d-bf75-98ecd6939605",
            url: "https://scan.sc/2smSZ2NmliKCyoGkoyYGE2",
            bgColor: "transparent",
            fgColor: "#000000",
          },
        ],
        artboards: [
          {
            objects: [
              {
                top: 211.87,
                fill: "#000000",
                left: 369.8,
                type: "circle",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 100,
                clipTo: null,
                height: 100,
                radius: 50,
                scaleX: 20.22,
                scaleY: 20.22,
                shadow: null,
                stroke: null,
                opacity: 0.1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                endAngle: 6.283185307179586,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                startAngle: 0,
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                src: CanvasImages[Media.CANVAS_SCAN_QR].source,
                top: 81.95,
                _ref: {
                  id: "651144dc-7a97-4141-be56-94e8159291f9",
                  type: "file",
                },
                fill: "rgb(255,255,255)",
                left: 889.16,
                type: "image",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 1153,
                clipTo: null,
                height: 1965,
                scaleX: 0.77,
                scaleY: 0.77,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                clipPath: {
                  rx: 0,
                  ry: 0,
                  top: -982.5,
                  fill: "rgb(0,0,0)",
                  left: -576.5,
                  type: "rect",
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  skewX: 0,
                  skewY: 0,
                  width: 1153,
                  clipTo: null,
                  height: 1965,
                  scaleX: 1,
                  scaleY: 1,
                  shadow: null,
                  stroke: null,
                  opacity: 1,
                  originX: "left",
                  originY: "top",
                  version: "3.4.0",
                  visible: true,
                  fillRule: "nonzero",
                  inverted: false,
                  paintFirst: "fill",
                  strokeWidth: 1,
                  strokeLineCap: "butt",
                  strokeLineJoin: "miter",
                  backgroundColor: "",
                  strokeDashArray: null,
                  transformMatrix: null,
                  strokeDashOffset: 0,
                  strokeMiterLimit: 4,
                  absolutePositioned: false,
                  globalCompositeOperation: "source-over",
                },
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "Anonymous",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
              {
                top: 255.17,
                fill: textColorForLightBackground,
                left: 86.48,
                text: "Join the conversation",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 936.12,
                clipTo: null,
                height: 245.6,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 120,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 0.8112094395280235,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: 556.31,
                fill: textColorForLightBackground,
                left: 91.32,
                text:
                  "Participate in employee surveys and provide valuable feedback.\n\nEach participant will receive a $20 Amazon gift card. ",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 711.69,
                clipTo: null,
                height: 363.28,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {
                  "2": {
                    "32": {
                      fontWeight: "bold",
                    },
                    "33": {
                      fontWeight: "bold",
                    },
                    "34": {
                      fontWeight: "bold",
                    },
                    "35": {
                      fontWeight: "bold",
                    },
                    "36": {
                      fontWeight: "bold",
                    },
                    "37": {
                      fontWeight: "bold",
                    },
                    "38": {
                      fontWeight: "bold",
                    },
                    "39": {
                      fontWeight: "bold",
                    },
                    "40": {
                      fontWeight: "bold",
                    },
                    "41": {
                      fontWeight: "bold",
                    },
                    "42": {
                      fontWeight: "bold",
                    },
                    "43": {
                      fontWeight: "bold",
                    },
                    "44": {
                      fontWeight: "bold",
                    },
                    "45": {
                      fontWeight: "bold",
                    },
                    "46": {
                      fontWeight: "bold",
                    },
                    "47": {
                      fontWeight: "bold",
                    },
                    "48": {
                      fontWeight: "bold",
                    },
                    "49": {
                      fontWeight: "bold",
                    },
                    "50": {
                      fontWeight: "bold",
                    },
                    "51": {
                      fontWeight: "bold",
                    },
                    "52": {
                      fontWeight: "bold",
                    },
                    "53": {
                      fontWeight: "bold",
                    },
                  },
                },
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 56,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "left",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "normal",
                hasBorders: true,
                lineHeight: 0.9481668773704174,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                top: -9.41,
                fill: "#ffffff",
                left: 1924.86,
                type: "circle",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 100,
                clipTo: null,
                height: 100,
                radius: 50,
                scaleX: 6.83,
                scaleY: 6.83,
                shadow: null,
                stroke: null,
                opacity: 0.1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                endAngle: 6.283185307179586,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                startAngle: 0,
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: true,
                  br: true,
                  mb: true,
                  ml: true,
                  mr: true,
                  mt: true,
                  tl: true,
                  tr: true,
                  mtr: true,
                },
                globalCompositeOperation: "source-over",
              },
              {
                top: 812.2,
                fill: textColorForLightBackground,
                left: 1271.58,
                text: "Scan to view on your phone",
                type: "textbox",
                angle: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 260.07,
                clipTo: null,
                height: 83.88,
                scaleX: 1,
                scaleY: 1,
                shadow: null,
                stroke: null,
                styles: {},
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                editable: true,
                fillRule: "nonzero",
                fontSize: 36,
                minWidth: 20,
                overline: false,
                fontStyle: "normal",
                textAlign: "center",
                underline: false,
                fontFamily: "Lato",
                fontWeight: "bold",
                hasBorders: true,
                lineHeight: 1.0619469026548674,
                paintFirst: "fill",
                charSpacing: 0,
                hasControls: true,
                linethrough: false,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                splitByGrapheme: false,
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                _controlsVisibility: {
                  bl: false,
                  br: false,
                  mb: false,
                  ml: true,
                  mr: true,
                  mt: false,
                  tl: false,
                  tr: false,
                  mtr: true,
                },
                textBackgroundColor: "",
                globalCompositeOperation: "source-over",
              },
              {
                src: "data:image/png;base64",
                top: 303.31,
                _ref: {
                  id: "85e20501-66c7-4a2d-bf75-98ecd6939605",
                },
                fill: "rgb(0,0,0)",
                left: 1151.31,
                type: "qrcode",
                angle: 0,
                cropX: 0,
                cropY: 0,
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                width: 800,
                clipTo: null,
                height: 800,
                scaleX: 0.6,
                scaleY: 0.6,
                shadow: null,
                stroke: null,
                filters: [],
                opacity: 1,
                originX: "left",
                originY: "top",
                version: "3.4.0",
                visible: true,
                fillRule: "nonzero",
                hasBorders: true,
                paintFirst: "fill",
                crossOrigin: "",
                hasControls: true,
                strokeWidth: 0,
                lockRotation: false,
                lockMovementX: false,
                lockMovementY: false,
                strokeLineCap: "butt",
                lockUniScaling: false,
                strokeLineJoin: "miter",
                backgroundColor: "",
                strokeDashArray: null,
                transformMatrix: null,
                strokeDashOffset: 0,
                strokeMiterLimit: 4,
                globalCompositeOperation: "source-over",
              },
            ],
            version: "3.4.0",
            background: lightBackgroundColor,
          },
        ],
        canvasWidth: 1920,
        customFonts: [
          {
            label: "Sora",
            value: "sc_Sora_1c4bb157-d489-473a-9ea3-a903ce2c2f61",
            customFontUrl:
              "data:text/css;base64,QGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBub3JtYWw7CiAgICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7CiAgICAgIHNyYzogdXJsKCJodHRwczovL21lZGlhLnVzLnNjcmVlbmNsb3VkLmNvbS8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEvb3JpZ2luYWxzL2M2MjRjMjY1LTNkZDAtNDE4Yi1iMGM1LWJlM2RkNDIxMjM5M19Tb3JhLVJlZ3VsYXIudHRmIik7CiAgICB9CkBmb250LWZhY2UgewogICAgICBmb250LWZhbWlseTogInNjX1NvcmFfMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxIjsKICAgICAgZm9udC1zdHlsZTogbm9ybWFsOwogICAgICBmb250LXdlaWdodDogYm9sZDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvZWRhODY3NWQtODkwOS00YzVkLTg4MzctODViN2VhYzVkOGZjX1NvcmEtRXh0cmFCb2xkLnR0ZiIpOwogICAgfQpAZm9udC1mYWNlIHsKICAgICAgZm9udC1mYW1pbHk6ICJzY19Tb3JhXzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MSI7CiAgICAgIGZvbnQtc3R5bGU6IGl0YWxpYzsKICAgICAgZm9udC13ZWlnaHQ6IG5vcm1hbDsKICAgICAgc3JjOiB1cmwoImh0dHBzOi8vbWVkaWEudXMuc2NyZWVuY2xvdWQuY29tLzFjNGJiMTU3LWQ0ODktNDczYS05ZWEzLWE5MDNjZTJjMmY2MS9vcmlnaW5hbHMvMmRlMTU1ODQtZDVmMC00NjRjLTk2ZDEtYzkyYzM3ZmNjZTFhX1NvcmEtQm9sZC50dGYiKTsKICAgIH0KQGZvbnQtZmFjZSB7CiAgICAgIGZvbnQtZmFtaWx5OiAic2NfU29yYV8xYzRiYjE1Ny1kNDg5LTQ3M2EtOWVhMy1hOTAzY2UyYzJmNjEiOwogICAgICBmb250LXN0eWxlOiBpdGFsaWM7CiAgICAgIGZvbnQtd2VpZ2h0OiBib2xkOwogICAgICBzcmM6IHVybCgiaHR0cHM6Ly9tZWRpYS51cy5zY3JlZW5jbG91ZC5jb20vMWM0YmIxNTctZDQ4OS00NzNhLTllYTMtYTkwM2NlMmMyZjYxL29yaWdpbmFscy82ZjMyYzA5MS0zN2I5LTQzYWMtYjJjOC1jNTU0NDc1NzE5ZTNfU29yYS1MaWdodC50dGYiKTsKICAgIH0=",
          },
        ],
        canvasHeight: 1080,
      },
      thumbnail: CanvasThumbnailImages[Media.CANVAS_THUMBNAIL_QRCODE],
      images: [CanvasImages[Media.CANVAS_SCAN_QR]],
    },
  };

  return canvasesMeta;
};
