import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  Label,
  Popover,
  TagList,
  Theme,
  Thumbnail,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { File, Site, SiteFragment, SiteType } from "../../types.g";
import DateTime from "../DateTime";
import { Styled } from "./styles";

import { EntityType } from "@screencloud/signage-firestore-client";
import { AppContext } from "../../AppContextProvider/AppContext";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import { CastedScreen, renderCastingStatus } from "../../helpers/castingHelper";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { AppContextState, AppContextType } from "src/AppContextProvider/type";
import { getShareButtonContent, isShared } from "src/helpers/shareableHelper";

export enum SiteListItemActions {
  CAST = "CAST",
  DELETE = "DELETE",
  MOVE = "MOVE",
  PREVIEW = "PREVIEW",
  RENAME = "RENAME",
  CLOSE_PREVIEW = "CLOSE_PREVIEW",
  SHARE = "SHARE",
  SELECTED = "SELECTED",
  FAVORITE = "FAVORITE",
  CHECKED = "CHECKED",
  VIEW = "VIEW",
  ADD = "ADD",
  REFETCH = "REFETCH",
  MANAGE_USAGE = "MANAGE_USAGE",
}

export enum SiteListFilters {
  ALL = "ALL",
  STARRED = "STARRED",
}

export interface SiteListItemPayload {
  nodeId: string;
  event: React.SyntheticEvent<any>;
  data: any;
  action: SiteListItemActions;
}

export type SiteListItemCallBack = (
  site: SiteFragment,
  action: SiteListItemActions,
  value?: string | boolean
) => void;

export interface SiteListItemProps {
  callBack: SiteListItemCallBack;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions
  ) => void;
  media?: Partial<File>;
  site: SiteFragment;
  tags?: string[];
  className?: string;
  selected?: boolean;
  preview?: React.ReactNode;
  previewOpen: boolean;
  isCompactLayout?: boolean;
  siteType?: string;
  castedScreens?: number;
  castedScreensData?: CastedScreen[];
  showSecureSiteColumns: boolean;
  onSaveSiteNameCallback: (name: string) => void;
  shareSiteCallback: (site: Site) => void;
  canUpdate: boolean;
}

export interface SiteListItemState {
  isRenameState: boolean;
  isDeleteConfirmOpen: boolean;
  isPreviewOpen: boolean;
  siteListItemPayload: SiteListItemPayload | null;
}

class SiteListItem extends React.Component<
  SiteListItemProps,
  SiteListItemState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  private unsubscribeLiveUpdateFn?: () => void;

  constructor(props: SiteListItemProps) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      isPreviewOpen: false,
      isRenameState: false,
      siteListItemPayload: null,
    };
  }

  public componentDidMount() {
    this.subscribeToLiveUpdate();
  }

  public componentDidUpdate(props, state) {
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate() {
    this.unsubscribeFromLiveUpdate();
    if (this.props.media && this.props.media.id) {
      this.unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        this.props.media.orgId || "",
        EntityType.FILE,
        this.props.media.id,
        () => {
          this.props.callBack(this.props.site.id, SiteListItemActions.REFETCH);
        },
        true
      );
    }
  }

  public unsubscribeFromLiveUpdate() {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  }

  public handleActionClick = (
    site: SiteFragment,
    action: SiteListItemActions,
    value?: string | boolean
  ) => {
    this.props.callBack(site, action, value);
  };

  public handleUrlClick = () => {
    window.open(this.props.site.url);
  };

  public handleFavorite = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (this.props.callBack) {
      this.props.callBack(
        this.props.site,
        SiteListItemActions.FAVORITE,
        !this.props.site.isFavorite
      );
    }
  };

  public handleView = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (this.props.callBack) {
      this.props.callBack(this.props.site, SiteListItemActions.VIEW);
    }
  };

  public renderTitle = () => {
    return (
      <h3 title={this.props.site.name}>
        <InlineInput
          value={this.props.site.name}
          onSaved={(_, value) => this.props.onSaveSiteNameCallback(value)}
          placeholder={this.props.site.name}
          showIcon
          disabled={!this.props.canUpdate}
        />
      </h3>
    );
  };

  public render(): JSX.Element | any {
    const thumbnail = this.props.media
      ? generateImgixThumbnail(
          this.props.media as File,
          this.context.secureMediaPolicy,
          true
        )
      : "";
    const siteThumbnail = <Thumbnail src={thumbnail} size="medium" />;
    const { name, userByCreatedBy, createdAt, isFavorite } = this.props.site;
    let siteItemClassname = "site-item";

    if (!this.props.site.actions) {
      siteItemClassname += " readonly";
    }

    if (this.props.selected) {
      siteItemClassname += " selected";
    }

    if (this.props.isCompactLayout) {
      siteItemClassname += ` site-item-small`;
    }

    const isStarringEnabled = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.STARRING
    );
    const isCastingEnabled = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.CASTING
    );
    const canCastSite = this.context.currentPermissions.validateCurrentSpace(
      "site",
      "cast"
    );
    const canDeleteSite = this.context.currentPermissions.validateCurrentSpace(
      "site",
      "delete"
    );
    const canShareSite = this.context.currentPermissions.validateCurrentSpace(
      "site",
      "share"
    );
    const isSecureSite = this.props.site?.type === SiteType.Secure;
    const isSharedSite = isShared(this.props.site);
    return (
      <Styled
        className={siteItemClassname}
        showSecureSiteColumns={this.props.showSecureSiteColumns}
      >
        <div className="site-core">
          <div
            className="site-name"
            onClick={() =>
              this.handleActionClick(this.props.site, SiteListItemActions.VIEW)
            }
          >
            <div className="thumbnail-preview" onClick={this.handleView}>
              {siteThumbnail}
            </div>
            <div className="site-title">
              {this.renderTitle()}
              <div>
                <span className="site-item__date">
                  <FormattedMessage
                    id="common.text.created_on"
                    defaultMessage="Created on"
                  />{" "}
                  <DateTime value={createdAt} />{" "}
                  <FormattedMessage id="common.text.by" defaultMessage="by" />{" "}
                  {userByCreatedBy
                    ? userByCreatedBy.givenName +
                      " " +
                      userByCreatedBy?.familyName!
                    : ""}
                </span>
              </div>
            </div>
          </div>

          {isStarringEnabled && (
            <div className="site-starred">
              <ToggleStar
                active={isFavorite ? isFavorite : false}
                onClick={this.handleFavorite}
              />
            </div>
          )}
          <div className="site-casting">
            {renderCastingStatus({
              castScreenData: this.props.site.castedScreenBySiteId?.nodes ?? [],
              callBack: this.props.castedScreensCallback!,
              context: this.context,
            })}
          </div>

          <div className="site-type">
            {isSecureSite ? (
              <FormattedMessage
                id="ui_component.site.type_secure"
                defaultMessage="Dashboard"
              />
            ) : (
              <FormattedMessage
                id="ui_component.site.type_basic"
                defaultMessage="Basic site"
              />
            )}
          </div>

          <div className="site-compute">
            {this.props.site.compute &&
              this.renderComputeLabel(this.props.site.compute)}
          </div>

          <div className="site-tags">
            {this.props.tags && <TagList taglist={this.props.tags} />}
          </div>

          <div className="site-share">
            {isSharedSite && (
              <div data-testid="site-shared-icon" className="site-shared-icon">
                <Popover
                  trigger={
                    <Button
                      onClick={() => {
                        this.props.shareSiteCallback(this.props.site as Site);
                      }}
                      icon
                      borderless
                      mini
                      className="share-icon"
                    >
                      <Icon name="users" />
                    </Button>
                  }
                  content={getShareButtonContent({
                    context: this.context as AppContextState,
                    shareable: this.props.site,
                  })}
                  position="top center"
                  inverted
                />
              </div>
            )}
          </div>
        </div>

        <div className="site-options">
          <Dropdown
            checkEmpty
            icon={<Icon name="dots" />}
            className="site-dropdown"
          >
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigator.clipboard.writeText(this.props.site.id);
                }}
              >
                <Icon name="clipboard" />
                <FormattedMessage
                  id="ui_component.common.label.copy_dashboard_id"
                  defaultMessage="Copy Dashboard ID"
                />
              </DropdownItem>
              {isCastingEnabled && canCastSite && (
                <DropdownItem
                  onClick={() =>
                    this.handleActionClick(
                      this.props.site,
                      SiteListItemActions.CAST
                    )
                  }
                >
                  <Icon name="casting" />
                  <FormattedMessage
                    id="ui_component.common.label.set_to_screen"
                    defaultMessage="Set to Screen"
                  />
                </DropdownItem>
              )}
              {canShareSite && (
                <DropdownItem
                  onClick={() =>
                    this.handleActionClick(
                      this.props.site,
                      SiteListItemActions.SHARE
                    )
                  }
                >
                  <Icon name="share" />
                  <FormattedMessage
                    id="ui_component.common.label.site"
                    defaultMessage="Share"
                  />
                </DropdownItem>
              )}
              {((isCastingEnabled && canCastSite) || canShareSite) && (
                <DropdownDivider />
              )}
              {canDeleteSite && (
                <DropdownItem
                  onClick={() =>
                    this.handleActionClick(
                      this.props.site,
                      SiteListItemActions.DELETE,
                      name
                    )
                  }
                  className="danger"
                >
                  <Icon name="trash" />{" "}
                  <FormattedMessage
                    id="ui_component.common.label.delete_site"
                    defaultMessage="Delete Site"
                  />
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </Styled>
    );
  }

  private renderComputeLabel(compute: {
    cpu: number;
    memory: number;
  }): JSX.Element {
    const { cpu, memory } = compute;
    let name = this.context.intl.formatMessage({
      id: "ui_component.site.compute.custom",
      defaultMessage: "Custom",
    });
    let color = Theme.color.viewer;
    let isBackgroundLight = false;

    if (cpu === 256 && memory === 512) {
      name = this.context.intl.formatMessage({
        id: "ui_component.site.compute.small",
        defaultMessage: "Small",
      });
      color = Theme.color.silver;
      isBackgroundLight = true;
    } else if (cpu === 512 && memory === 1024) {
      name = this.context.intl.formatMessage({
        id: "ui_component.site.compute.medium",
        defaultMessage: "Medium",
      });
      color = Theme.color.lightGrey;
      isBackgroundLight = true;
    } else if (cpu === 1024 && memory === 2048) {
      name = this.context.intl.formatMessage({
        id: "ui_component.site.compute.large",
        defaultMessage: "Large",
      });
      color = Theme.color.darkGrey;
      isBackgroundLight = false;
    }

    const textColor = isBackgroundLight
      ? Theme.color.darkGrey
      : Theme.color.white;

    return (
      <Popover
        inverted
        position="bottom center"
        header={name}
        content={
          <div>
            <div>{cpu} vCPU</div>
            <div>{memory} MB RAM</div>
          </div>
        }
        trigger={
          <Label size="mini" customColor={color} circular>
            <span style={{ color: textColor }}>{name}</span>
          </Label>
        }
      />
    );
  }
}

export default SiteListItem;
