import { UUID } from "@screencloud/uuid";
import {
  AppInfo,
  Apps,
  AppInstances,
  getAppWithInstances,
} from "../config/apps";
import apolloClient from "src/state/apolloClient";
import {
  InstallOnboardingAppMutation,
  InstallOnboardingAppMutationVariables,
  InstallOnboardingAppDocument,
  AvailableAppInstallsBySpaceIdDocument,
  AvailableAppInstallsBySpaceIdQuery,
  AvailableAppInstallsBySpaceIdQueryVariables,
  AllAppsDocument,
  AllAppsQuery,
  AllAppsQueryVariables,
  App,
  CreateOnboardingAppInstanceMutationVariables,
  CreateOnboardingAppInstanceDocument,
  CreateOnboardingAppInstanceMutation,
  Scalars,
  UpdateOnboardingAppInstanceMutationVariables,
  UpdateOnboardingAppInstanceMutation,
  UpdateOnboardingAppInstanceDocument,
} from "src/types.g";
import { find, flatten } from "lodash";
import { executePromise } from "../execute";
import {
  createQuickPostConfig,
  createQuickPostContent,
} from "./platformApps/quickPost";
import { createAppManagementToken } from "src/state/helper/query";

export type OnboardingAppInstance = {
  id: UUID;
  name: string;
  config: Scalars["JSON"];
};

export const getAllApps = async () => {
  const { data } = await apolloClient.query<
    AllAppsQuery,
    AllAppsQueryVariables
  >({
    query: AllAppsDocument,
  });
  return data?.allApps?.nodes as App[];
};

export const getInstalledAppsBySpaceId = async (spaceId: UUID) => {
  const { data } = await apolloClient.query<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >({
    query: AvailableAppInstallsBySpaceIdDocument,
    variables: {
      spaceId,
    },
  });
  return data?.spaceById?.availableAppInstallsBySpaceId?.nodes;
};

export const installApp = async (appId: UUID, spaceId: UUID) => {
  const allInstalledApps = await getInstalledAppsBySpaceId(spaceId);
  const installedApp =
    allInstalledApps && allInstalledApps.find((app) => app.appId === appId);
  if (installedApp) {
    return installedApp;
  } else {
    const variables: InstallOnboardingAppMutationVariables = {
      input: {
        appId,
        spaceId,
      },
    };
    const { data } = await apolloClient.mutate<
      InstallOnboardingAppMutation,
      InstallOnboardingAppMutationVariables
    >({
      mutation: InstallOnboardingAppDocument,
      variables,
    });
    return data?.installSpaceApp?.appInstall;
  }
};

export const createAppInstance = async (
  appId: UUID,
  spaceId: UUID,
  config: Scalars["JSON"],
  name: string,
  version: string
): Promise<OnboardingAppInstance> => {
  const variables: CreateOnboardingAppInstanceMutationVariables = {
    input: {
      appInstallId: appId,
      config,
      name,
      spaceId,
      version,
    },
  };
  const { data } = await apolloClient.mutate<
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables
  >({
    mutation: CreateOnboardingAppInstanceDocument,
    variables,
  });
  return {
    ...data?.createAppInstance?.appInstance,
    config,
  } as OnboardingAppInstance;
};

export const updateAppInstance = async (
  updateInput: UpdateOnboardingAppInstanceMutationVariables
) => {
  const { data } = await apolloClient.mutate<
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables
  >({
    mutation: UpdateOnboardingAppInstanceDocument,
    variables: updateInput,
  });
  return {
    ...data?.updateAppInstance?.appInstance,
    config: updateInput.input.config,
  } as OnboardingAppInstance;
};

export const installAppAndAppInstances = async (
  appsToBeInstalled: AppInfo[],
  spaceId: UUID,
  countryCode: string
): Promise<OnboardingAppInstance[]> => {
  const allApps = await getAllApps();
  let appInstanceMeta = getAppWithInstances(countryCode);
  const installedApps = await Promise.all(
    appsToBeInstalled.map(async (appToBeInstalled) => {
      const { appName, appInstancesName } = appToBeInstalled;
      const app = find(allApps, { name: appName });
      if (app) {
        if (app.name === Apps.QUICK_POST) {
          try {
            const signedToken = await createAppManagementToken(spaceId);

            const config = await createQuickPostConfig(signedToken);
            await createQuickPostContent(signedToken, config.hookId);

            appInstanceMeta = {
              ...appInstanceMeta,
              [Apps.QUICK_POST]: {
                [AppInstances.QUICK_POST]: {
                  config,
                  version: "stable",
                },
              },
            };
          } catch (error) {
            console.error("Failed to create Quick Post on Platform", error);
            return;
          }
        }

        const installedApp = await installApp(app.id, spaceId);
        const installedAppInstnacesPromises = appInstancesName
          .filter((appInstanceName) => {
            const instance = appInstanceMeta[appName][appInstanceName];
            return appInstanceMeta && instance;
          })
          .filter(Boolean)
          .map((appInstanceName) => {
            const instance = appInstanceMeta[appName][appInstanceName];
            return createAppInstance(
              installedApp?.id,
              spaceId,
              instance.config,
              appInstanceName,
              instance.version
            );
          });

        const installedAppInstances = await executePromise(
          installedAppInstnacesPromises
        );
        return installedAppInstances.results.filter(Boolean);
      } else {
        return undefined;
      }
    })
  );

  return flatten(installedApps.filter(Boolean)) as OnboardingAppInstance[];
};
